.careers {
	
	.page__label::after {
		content: "Carrees";
	}
	
	#work__with__us {
		display: flex;
		flex-direction: column;
		justify-content: center;
		align-items: center;
		text-align: center;
		min-height: 100vh;
		
		@include breakpoint(small only) {
			min-height: calc(100vh - 90px);
		}
		
		@media only screen and (min-device-width: 768px) and (max-device-width: 1023px) {
			min-height: calc(100vh - 285px);
		}
		
		.heading {
			font-size: rem-calc(16);
			color: $gray;
			letter-spacing: rem-calc(12);
		}
		
		.slashes {
			width: rem-calc(50);
			margin: auto;
			margin-bottom: rem-calc(30);
			border-bottom: 1px solid $yellow;
			
			.slash {
				color: transparent;
			}
		}
		.text {
			@include breakpoint(small only) {
				font-size: rem-calc(20);
				line-height: rem-calc(32);
			}
		}
		
		.down__arrow__outer {
			margin-top: rem-calc(40);
		}
		.text__block {
			&.centered {
				@include breakpoint(large up) {
					max-width: rem-calc(900);
				}
			}
		}
		
	}
	
	.text__block {
		z-index: 2;
		
		@include breakpoint(small only) {
			max-width: rem-calc(400);
			margin: auto;
		}
		
		.text {
			&.clean {
				@include breakpoint(1300px) {
					font-size: rem-calc(14);
					line-height: rem-calc(42);
					letter-spacing: rem-calc(6);
				}
			}
		}
	}
	
	#careers__main {
		//@include breakpoint(small only)
		//{
		//    margin-top: rem-calc(-66);
		//}
		
		@include breakpoint(medium up) {
			position: relative;
			margin-top: rem-calc(-195);
		}
		
		@media only screen and (min-device-width: 768px) and (max-device-width: 1024px) {
			margin-top: rem-calc(40);
		}
		
		@media only screen and (min-device-width: 768px) and (max-device-width: 1024px) and (orientation: landscape) {
			margin-top: rem-calc(-150);
		}
		
		.text__block__wrapper {
			display: block;
			text-align: center;
			
			.text__block {
				&#careers__text__block {
					text-align: left;
					padding: 0px;
					padding-left: rem-calc(0);
					
					@include breakpoint(small only) {
						text-align: center;
						padding-left: 0px;
					}
					
					.underlay__wrapper {
						height: auto;
						
						.underlay {
							position: relative;
							top: initial;
							right: initial;
							bottom: initial;
							left: initial;
							z-index: 1;
							color: #eeeeee;
							
							@include breakpoint(small only) {
								font-size: rem-calc(60);
								margin-bottom: rem-calc(-52);
							}
							
							@include breakpoint(medium up) {
								font-size: rem-calc(130);
								top: rem-calc(110);
							}
							
							@media only screen and (min-device-width: 768px) and (max-device-width: 1024px) and (orientation: landscape) {
								font-size: rem-calc(100);
								top: rem-calc(85);
							}
						}
					}
				}
			}
		}
		
		.lists__container {
			position: relative;
			text-align: center;
			padding-top: rem-calc(70);
			padding-left: rem-calc(20);
			background-color: white;
			padding-bottom: rem-calc(30);
			
			@include breakpoint(large up) {
				//display: block;
				text-align: left;
				vertical-align: top;
				//padding-top: rem-calc(30);
				//padding-left: rem-calc(50);
				padding: rem-calc(100) rem-calc(20) rem-calc(60) rem-calc(50);
				width: 100%;
				height: 100%;
			}
			
			.list__item__wrapper {
				padding-right: rem-calc(20);
				padding-bottom: rem-calc(50);
				
				@include breakpoint(large up) {
					margin: 0px;
					
				}
				
				.job__item {
					border-bottom: 1px solid $orange;
					margin-bottom: rem-calc(30);
					
					@include breakpoint(medium up) {
						padding-left: rem-calc(55);
						padding-right: rem-calc(55);
					}
					
					@include breakpoint(large up) {
						max-width: none;
					}
				}
				
				.job__item:last-child {
					margin-bottom: 0;
					border-bottom: none;
					.career__item {
						.text__block {
							padding-bottom: 0;
						}
					}
				}
				
				.list__item {
					padding-left: rem-calc(20);
					text-align: left;
					
					.job__title {
						text-transform: uppercase;
						font-family: 'ButlerStencil-ExtraBold';
						font-size: rem-calc(25);
						letter-spacing: rem-calc(6);
						color: $yellow;
						position: relative;
						letter-spacing: rem-calc(6);
						
						@include breakpoint(medium up) {
							font-size: rem-calc(50);
							letter-spacing: rem-calc(0);
						}
						
						@include breakpoint(large up) {
							letter-spacing: rem-calc(0);
						}
						@include breakpoint(1400px) {
							letter-spacing: rem-calc(6);
							
						}
					}
					
					.experience {
						font-family: 'FreightTextBookItalic';
						font-size: rem-calc(25);
						line-height: rem-calc(24);
						letter-spacing: rem-calc(1);
						word-spacing: rem-calc(1);
						color: $dark-blue;
						margin-top: rem-calc(10);
					}
					.qualifications, .requirements {
						padding-top: rem-calc(50);
						.heading {
							color: $gray;
						}
					}
					
					.requirements, .qualifications {
						.sub__list__container {
							padding: rem-calc(20) rem-calc(50);
						}
					}
					
					.dash {
						width: rem-calc(35);
						height: rem-calc(1);
						background: $yellow;
						margin-bottom: rem-calc(20);
						margin-left: rem-calc(5);
					}
					
					.sub__list__container {
						.sub__list__item {
							position: relative;
							
							.icon__wrapper {
								display: inline-block;
								//position: relative;
								position: absolute;
								// bottom: rem-calc(3);
								top: -3px;
								left: rem-calc(-25);
								
								.plus__icon {
									font-size: rem-calc(13);
								}
							}
							
							.text {
								display: inline-block;
								font-family: 'FreightTextBookItalic';
								font-size: rem-calc(24);
								color: $gray;
							}
						}
					}
				}
				
				.career__item {
					.dash {
						width: rem-calc(50);
						margin: rem-calc(30) 0;
					}
					.text__block {
						.text {
							color: $ash-gray;
							font-size: rem-calc(20);
							line-height: rem-calc(34);
							max-width: rem-calc(500);
							margin: auto;
							
							@include breakpoint(medium up) {
								font-size: rem-calc(30);
								line-height: rem-calc(44);
								max-width: none;
							}
							
							@include breakpoint(medium only) {
								color: $dark-blue
							}
							
							@include breakpoint(large up) {
								font-size: rem-calc(24);
								line-height: rem-calc(36);
								letter-spacing: rem-calc(2);
								word-spacing: rem-calc(2);
							}
						}
					}
				}
			}
		}
		.lists__container,
		#how__to__apply {
			@include breakpoint(large up) {
				display: inline-block;
			}
		}
		
		#how__to__apply {
			position: relative;
			border-top: 1px solid #eeeeee;
			background-color: #eeeeee;
			
			@include breakpoint(medium only) {
				padding-top: rem-calc(60);
			}
			
			@include breakpoint(large up) {
				display: block;
				width: 100%;
				padding-top: rem-calc(80);
			}

			@include breakpoint(2500px) {
				height: calc(100vh - 142px);
			}

			.lists__container {
				background-color: #eeeeee;
			}
			
			.arrow__wrapper {
				height: 0px;
				
				.arrow {
					background: $white;
					width: rem-calc(40);
					height: rem-calc(40);
					transform: rotate(45deg);
					position: absolute;
					top: rem-calc(-20);
					left: calc(50% - 25px);
					z-index: 1;
					
					@include breakpoint(large up) {
						top: rem-calc(122);
						left: rem-calc(-24);
					}
				}
			}
			
			.heading {
				text-align: left;
				text-transform: uppercase;
				font-family: 'Gotham-Medium';
				font-size: rem-calc(19);
				letter-spacing: rem-calc(6);
				color: $dark-blue;
				padding-bottom: rem-calc(25);
			}
			
			.dash {
				width: rem-calc(50);
				height: rem-calc(1);
				background: $yellow;
				margin-bottom: rem-calc(40);
				margin-left: rem-calc(5);
			}
			
			.text__block {
				padding-left: rem-calc(30);
				padding-right: rem-calc(20);
				
				@include breakpoint(medium up) {
					padding-left: rem-calc(70);
					padding-right: rem-calc(140);
				}
				
				@media only screen and (min-device-width: 768px) and (max-device-width: 1024px) and (orientation: landscape) {
					padding-left: rem-calc(40);
					padding-right: rem-calc(10);
				}
				
				.text {
					color: $gray;
					font-size: rem-calc(25);
					text-align: left;
					
					@include breakpoint(small only) {
						font-size: rem-calc(20);
						line-height: rem-calc(32);
					}
					
				}
				
				.orange {
					color: $orange;
				}
			}
			.apply__button__wrapper {
				padding: 0 0 rem-calc(35) rem-calc(30);
				
				@include breakpoint(medium up) {
					padding: rem-calc(35) rem-calc(140) rem-calc(100) rem-calc(70);
				}
				
				@media only screen and (min-device-width: 768px) and (max-device-width: 1024px) and (orientation: landscape) {
					padding-left: rem-calc(40);
					padding-right: rem-calc(10);
				}
				
				text-align: left;
				.apply__button {
					text-transform: uppercase;
					color: $yellow;
					letter-spacing: rem-calc(6);
					font-size: rem-calc(14);
					font-family: 'ButlerStencil-ExtraBold';
					background-color: $dark-blue;
					//border: 1px solid $yellow;
					padding: rem-calc(18) rem-calc(22) rem-calc(12) rem-calc(28);
					
					&:hover {
						background-color: $yellow;
						color: $white;
					}
				}
			}
		}
		
		#how__to__apply.has__stuck {
			top: 142px !important;
			
			@media only screen and (min-device-width: 768px) and (max-device-width: 1024px) and (orientation: landscape) {
				top: 120px !important;
			}
		}
	}
}