.overview__split {
    position: relative;

    .overview__left {
        border-left:   1px solid $yellow;
        //padding-left: rem-calc(20);
        padding-bottom: rem-calc(20);

        @include breakpoint(medium down)
        {
            margin-left: rem-calc(20);
            padding-right: rem-calc(20);
            padding-left: rem-calc(30);
        }

        @include breakpoint(medium up) {
            margin: rem-calc(40) 0px;
        }

        @include breakpoint(large up) {
            //max-width:    rem-calc(630);
            max-width:    rem-calc(850);
            min-height:   rem-calc(319);
            margin-top:   0px;
            margin-left:  rem-calc(100);
            padding-left: rem-calc(80);
            padding-right: rem-calc(200);
            position:     relative;
            top:          rem-calc(40);
        }

        .heading__overview {
            text-transform: uppercase;
            color:          $yellow;
            font-size:      rem-calc(12);
            font-weight:    bold;
            letter-spacing: rem-calc(6);

            @include breakpoint(medium up) {
                margin-bottom: rem-calc(15);
            }
        }

        .lead__text {
            font-family:    'Gotham-Medium';
            font-size:      rem-calc(20);
            //font-weight:    bold;
            //color:          $ash-gray;
            color: #ddd;
            letter-spacing: rem-calc(0);
            line-height:    1.2;

            @include breakpoint(medium up) {
                font-size:     rem-calc(32);
                margin-bottom: rem-calc(15);
            }
        }

        .overview__description {
            font-family:    'FreightTextBookItalic';
            font-size:      rem-calc(16);
            color:          $gray;
            letter-spacing: rem-calc(1);
            line-height:    1.3;
            word-spacing:   rem-calc(2);

            @include breakpoint(medium up) {
                font-size: rem-calc(20);
            }
        }

        .overview__arrow {
            width:         rem-calc(14);
            height:        rem-calc(14);
            border-right:  1px solid $yellow;
            border-bottom: 1px solid $yellow;
            transform:     rotate(45deg);
            //position: absolute;
            //bottom: 0px;
            //cursor:        pointer;
        }
    }

    .location__details {
        @include breakpoint(large up) {
            padding-top:  rem-calc(50);
            padding-left: rem-calc(20);
            background:   #eeeeee;
            width:        rem-calc(420);
            //height: rem-calc(400);
            height:       100%;
            position:     absolute;
            right:        0px;

            @include breakpoint(1200px) {
                width:        rem-calc(500);
                padding-left: rem-calc(50);
            }
        }

        .list__item {
        padding-left: rem-calc(30);
        text-align:   left;

            .number__wrapper {
                display: inline-block;
                width:   0px;

                .number {
                    text-transform: uppercase;
                    font-family:    'ButlerStencil-ExtraBold';
                    font-size:      rem-calc(16);
                    letter-spacing: rem-calc(2);
                    color:          $yellow;
                    position:       relative;
                    right:          rem-calc(30);
                    bottom:         rem-calc(5);
                }
            }

            .heading {
                display:        inline-block;
                text-transform: uppercase;
                font-family:    'Gotham-Medium';
                font-size:      rem-calc(12);
                letter-spacing: rem-calc(6);
                color:          $dark-blue;
                padding-bottom: rem-calc(8);
            }

            .dash {
                width:         rem-calc(35);
                height:        rem-calc(1);
                background:    $yellow;
                margin-bottom: rem-calc(20);
                margin-left:   rem-calc(5);
            }

            .sub__list__container {
                .sub__list__item {
                    .icon__wrapper {
                        display:  inline-block;
                        position: relative;
                        right:    rem-calc(25);
                        bottom:   rem-calc(5);

                        .icon {
                            .line {
                                width:            rem-calc(10);
                                height:           rem-calc(1);
                                position:         absolute;
                                background-color: $gray;

                                &.line-1 {

                                }

                                &.line-2 {
                                    transform: rotate(90deg);
                                }
                            }
                        }
                    }

                    .text 
                    {
                        color:       $gray;
                    }
                }
            }
        }

        .location 
        {
            margin-bottom: rem-calc(50);

            .text 
            {
                font-family: 'FreightTextBookItalic';
            }
        }

        .size {
            .text {
                font-family:    'Gotham-Medium';
                font-size:      rem-calc(8);
                text-transform: uppercase;
                letter-spacing: rem-calc(2);

                span {
                    font-size:      rem-calc(15);
                    font-family:    'FreightTextBookItalic';
                    margin-left:    rem-calc(5);
                    text-transform: lowercase;
                }
            }
        }

        .arrow__wrapper {
            .arrow {
                top:  rem-calc(100);
                left: rem-calc(-30);
            }
        }

        .year__label__wrapper {
            height: 0px;

            .year__label {
                text-align:  right;
                font-family: 'ButlerStencil-ExtraBold';
                font-size:   rem-calc(90);
                color:       $white;
                position:    absolute;
                bottom:      rem-calc(-50);
                right:       rem-calc(50);
                height: rem-calc(140);

            }
        }
    }
}

    /*
    */