
@font-face 
{
  font-family: 'ButlerStencil-ExtraBold';
  src: url('/fonts/ButlerStencil-ExtraBold.eot?#iefix') format('embedded-opentype'),  
            url('/fonts/ButlerStencil-ExtraBold.otf')  format('opentype'),
	        url('/fonts/ButlerStencil-ExtraBold.woff') format('woff'), 
            url('/fonts/ButlerStencil-ExtraBold.ttf')  format('truetype'), 
            url('/fonts/ButlerStencil-ExtraBold.svg#ButlerStencil-ExtraBold') format('svg');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'FreightTextBookItalic';
  src: url('/fonts/FreightTextBookItalic.eot?#iefix') format('embedded-opentype'),  
            url('/fonts/FreightTextBookItalic.woff') format('woff'), 
            url('/fonts/FreightTextBookItalic.ttf')  format('truetype'), 
            url('/fonts/FreightTextBookItalic.svg#FreightTextBookItalic') format('svg');
  font-weight: normal;
  font-style: normal;
}


@font-face {
  font-family: 'FreightTextMediumItalic';
  src: url('/fonts/FreightTextMediumItalic.eot?#iefix') format('embedded-opentype'),  
            url('/fonts/FreightTextMediumItalic.woff') format('woff'), 
            url('/fonts/FreightTextMediumItalic.ttf')  format('truetype'), 
            url('/fonts/FreightTextMediumItalic.svg#FreightTextMediumItalic') format('svg');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Gotham-Medium';
  src: url('/fonts/Gotham-Medium.eot?#iefix') format('embedded-opentype'),  
       url('/fonts/Gotham-Medium.woff') format('woff'), 
       url('/fonts/Gotham-Medium.ttf')  format('truetype'), 
       url('/fonts/Gotham-Medium.svg#Gotham-Medium') format('svg');
  font-weight: normal;
  font-style: normal;
}
