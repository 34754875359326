$blog-item-height: 23vw;
$blog-item-height-for-small: 95vw;
$blog-item-height-featured: ($blog-item-height * 2);

.blog {

    .page__label::after
    {
        content: "Curiosities";
    }

    .text__block {
        z-index: 2;

        @include breakpoint(small only) {
            max-width: rem-calc(400);
            margin: auto;
        }

        .text {
            &.clean {
                @include breakpoint(1300px) {
                    font-size: rem-calc(14);
                    line-height: rem-calc(42);
                    letter-spacing: rem-calc(6);
                }
            }
        }
    }

    .side__labels {
        display: none;
        height: 0px;
        position: relative;

        @include breakpoint(large up) {
            display: block;

            .side__label {
                position: absolute;
                top: rem-calc(100);
                text-transform: uppercase;
                font-family: 'ButlerStencil-ExtraBold';
                font-size: rem-calc(14);
                letter-spacing: rem-calc(12);
                color: $ash-gray;

                &.label-1 {
                    // top: calc(50% - 30px);
                    left: rem-calc(18);
                    //transform: rotate(-90deg);
                }

                &.label-2 {
                    right: rem-calc(18);
                    //transform: rotate(90deg);
                }
            }
        }
    }

    #how__we__think {
        @include breakpoint(small only) {
            margin-top: rem-calc(180);
            margin-bottom: rem-calc(80);
        }

        @include breakpoint(medium up) {
            margin-top: rem-calc(180);
            margin-bottom: rem-calc(80);
        }

        .heading {
            font-size: rem-calc(16);
            color: $gray;
            letter-spacing: rem-calc(12);
        }

        .slashes {
            width: rem-calc(50);
            margin: auto;
            margin-bottom: rem-calc(20);
            border-bottom: 1px solid $yellow;

            .slash {
                color: transparent;
            }
        }

        .text {
            @include breakpoint(small only)
            {
                font-size: rem-calc(20);
                line-height: rem-calc(32);
            }
        }
    }

    .blog__posts__container {
        background-color: $lighter-gray;
        position: relative;

        .text_container {
            left: 0;
            margin: 0 auto;
            position: relative !important;
            top: 0;
            bottom: auto;
            padding-top: rem-calc(5);
            left: rem-calc(-5);
        }
        .text_container:before,
        .text_container:after {
            border: none;
        }
        .arrow__wrapper {
            height: 0px;

            .arrow {
                background: $lighter-gray;
                width: rem-calc(20);
                height: rem-calc(20);
                transform: rotate(45deg);
                position: absolute;
                top: rem-calc(45);
                left: calc(50% - 20px);
                z-index: 3;

                @include breakpoint(large up) {
                    top: rem-calc(45);
                    left: calc(50% - 18px);
                }
            }
        }

        .categories__nav {
            display: flex;
            justify-content: center;
            list-style: none;
            margin: rem-calc(55) 0;

            @include breakpoint(small only) {
                padding: rem-calc(20) 0;
                text-align: center;
            }

            li:first-child {
                padding: 0;
            }

            li {
                a {
                    color: $blue;
                    padding-left: rem-calc(50);
                    letter-spacing: rem-calc(6);
                    font-family: 'Gotham-Medium';
                    font-size: rem-calc(12);
                    text-transform: uppercase;

                    @include breakpoint(small only) {
                        padding-left: rem-calc(16);
                    }
                }
                a.active {
                    color: $orange;
                }
            }
        }
        .blog__contianer {
            display: grid;
            grid-template-columns: repeat(4, 25%);
            grid-template-rows: $blog-item-height;

            @include breakpoint(small only) {
                grid-template-columns: repeat(1, 100%);
                grid-template-rows: $blog-item-height-for-small;
            }

            grid-gap: 10px;

            .blog__post__item {
                position: relative;
                height: $blog-item-height;

                @include breakpoint(small only) {
                    height:  $blog-item-height-for-small;
                }

                .image__item {
                    @include breakpoint(medium up) {
                        height: $blog-item-height;
                    }

                    @include breakpoint(small only) {
                        height:  $blog-item-height-for-small;
                    }
                }

                .lower {
                    .author {
                        padding-top: rem-calc(0);
                    }

                    .abstract {
                        padding-bottom: rem-calc(50);
                    }
                }

                .image__item__wrapper {
                    padding: 0;
                    .image__overlay, .image__item, .overlay__content {
                        height: $blog-item-height;

                        @include breakpoint(small only) {
                            height:  $blog-item-height-for-small;
                        }
                    }
                    .image__overlay__wrapper {
                        bottom: $blog-item-height;

                        @include breakpoint(small only) {
                            bottom:  $blog-item-height-for-small;
                        }
                    }
                }

                .blog__button__wrapper {
                    .blog__button {
                        text-transform: uppercase;
                        color: white;
                        letter-spacing: 0.375rem;
                        font-size: 0.875rem;
                        font-family: 'ButlerStencil-ExtraBold';
                        background-color: transparent;
                    }
                }
                .blog__button__wrapper.read__more {
                    opacity: 0;
                    visibility: hidden;
                    transition: visibility 0s, opacity 0.5s linear;
                    .blog__button {
                        position: relative;
                        &:not(.active) {
                            &:before {
                                content:          "";
                                position:         absolute;
                                width:            0%;
                                height:           1px;
                                bottom:           -4px;
                                background-color: white;
                                transition:       all 0.4s linear;
                            }

                            &:hover:before {
                                width: 100%;
                            }

                        }
                    }
                }
            }
            .blog__post__item.featured {

                .overlay__content {
                    .overlay__content__inner {
                        .middle {
                            @include breakpoint(1400px) {
                                padding-top: rem-calc(50);
                            }

                            .overlay__text {
                                @include breakpoint(small only) {
                                    font-size: rem-calc(40);
                                    line-height: rem-calc(50);
                                }

                                @include breakpoint(medium) {
                                    font-size: rem-calc(40);
                                }

                                @include breakpoint(medium up) {
                                    max-width: rem-calc(300);
                                }

                                @include breakpoint(1400px) {
                                    font-size: rem-calc(60);
                                    line-height: rem-calc(60);
                                }

                                @media only screen
                                and (min-device-width: 768px)
                                and (max-device-width: 1024px)
                                and (orientation : landscape) {
                                    line-height: rem-calc(40);
                                }
                            }
                        }

                    }
                }

                .lower {
                    .author {
                        padding-top: rem-calc(0);
                    }
                }

                .blog__button__wrapper {
                    .blog__button {
                        border: 1px solid white;
                        padding: rem-calc(16) rem-calc(22) rem-calc(12) rem-calc(28);
                    }
                }


                @include breakpoint(medium up) {
                    grid-row-end: span 2;
                    grid-column: 1/-3;
                    height: calc(#{$blog-item-height-featured} + 10px);

                    .image__item__wrapper {
                        .image__overlay, .image__item, .overlay__content {
                            height: calc(#{$blog-item-height-featured} + 10px);
                        }
                        .image__overlay__wrapper {
                            bottom: calc(#{$blog-item-height-featured} + 10px);
                        }
                    }
                }
            }
        }
        .image__list {
            padding: rem-calc(50);

            @media only screen
            and (min-device-width: 768px)
            and (max-device-width: 1024px)
            and (orientation: portrait)
            and (-webkit-min-device-pixel-ratio: 1) {
                padding-left: rem-calc(25);
            }

            @include breakpoint(small only) {
                padding: rem-calc(20);
            }

            .blog__item {
                .image__overlay__wrapper {

                    .image__overlay {
                        background: rgba(37, 53, 79, 0.8);

                        @include breakpoint(small only) {
                            opacity: 1;
                            filter: none;
                        }

                        @include breakpoint(medium only) {
                            opacity: 1;
                            filter: none;
                        }
                    }

                    @include breakpoint(medium up) {
                        bottom: rem-calc(380);
                    }

                    @include breakpoint(1400px) {
                        bottom: rem-calc(440);
                    }
                }

                .overlay__content {

                    @include breakpoint(small only) {
                        opacity: 1;
                    }

                    @include breakpoint(medium up) {
                        height: rem-calc(380);
                    }

                    @include breakpoint(1400px) {
                        height: rem-calc(440);
                    }
                    .overlay__content__inner {

                        @media only screen
                        and (min-device-width: 768px)
                        and (max-device-width: 1024px)
                        and (orientation : landscape) {
                            padding-top: rem-calc(5);
                        }

                        .upper {
                            .category {
                                font-size: rem-calc(12);
                            }
                        }
                        .middle {
                            .overlay__text {
                                @include breakpoint(small only) {
                                    font-size: rem-calc(40);
                                    line-height: rem-calc(50);
                                }

                                @include breakpoint(medium up) {
                                    max-width: rem-calc(300);
                                }

                                @media only screen
                                and (min-device-width: 768px)
                                and (max-device-width: 1024px)
                                and (orientation : landscape) {
                                    padding: rem-calc(10) 0;
                                    line-height: rem-calc(25);
                                }
                            }
                        }
                    }
                }

                &.active {

                    opacity: unset;

                    .image__item {
                        filter: grayscale(100%);
                    }

                    .image__overlay__wrapper {
                        display: block;
                        opacity: 1;

                        .image__overlay {
                            opacity: 1;
                            filter: none;
                            transition: all 500ms linear;
                        }

                        .overlay__content {
                            opacity: 1;
                            -webkit-transition-delay: 0.4s; /* Safari */
                            transition-delay: 0.4s;

                            .overlay__content__inner {
                                .border {
                                    height: 100%;
                                    -webkit-transition-delay: 0.8s; /* Safari */
                                    transition-delay: 0.8s;
                                }
                            }

                        }
                    }
                }
                &:hover {
                    .image__overlay__wrapper {
                        .image__overlay {
                            background-color: rgba(102, 132, 163, 0.8);
                        }
                    }

                    .blog__button__wrapper.read__more {
                        opacity: unset;
                        visibility: visible;
                        .blog__button {
                            &:before {
                                width: 100%;
                            }
                        }
                    }
                }
            }
        }
    }

    .blog__container {
        opacity: unset;

        @media only screen
        and (min-device-width: 768px)
        and (max-device-width: 1024px)
        and (orientation : landscape) {
            margin-top: rem-calc(122);
        }

        @include breakpoint(1400px) {
            margin-top: rem-calc(142);
        }

        .left {
            padding-left: 0;
            padding-right: 0;

            .image__item__wrapper {
                opacity: unset;
                padding-left: 0;
                padding-right: 0;
                margin-bottom: 0;

                @include breakpoint(medium only) {
                    padding: 0;
                }

                @include breakpoint(small only) {
                    padding: 0;
                }

                .image__item {
                    background-size: cover;
                    background-position: 50%;
                    background-repeat: no-repeat;
                    filter: grayscale(100%);

                    @include breakpoint(medium up) {
                        height: rem-calc(380);
                    }

                    @media only screen
                    and (min-device-width: 768px)
                    and (max-device-width: 1024px)
                    and (orientation: landscape) {
                        height: calc(100vh - 100px)
                    }

                    @include breakpoint(1400px) {
                        height: calc(100vh - 142px);
                    }
                }

                .image__overlay__wrapper {
                    @include breakpoint(medium only) {
                        display: block;
                        opacity: 1;
                    }

                    @media only screen
                    and (min-device-width: 768px)
                    and (max-device-width: 1024px)
                    and (orientation: landscape) {
                        display: block;
                        opacity: 1;
                    }

                    @include breakpoint(medium up) {
                        bottom: rem-calc(380);
                    }

                    @media only screen
                    and (min-device-width: 768px)
                    and (max-device-width: 1024px)
                    and (orientation: landscape) {
                        bottom: calc(100vh - 100px);
                    }

                    @include breakpoint(1400px) {
                        bottom: calc(100vh - 142px);
                    }

                    .image__overlay {
                        background: rgba(37, 53, 79, 0.8);

                        @include breakpoint(medium only) {
                            opacity: 1;
                            filter: none;
                        }

                        @include breakpoint(medium up) {
                            height: rem-calc(380);
                        }

                        @media only screen
                        and (min-device-width: 768px)
                        and (max-device-width: 1024px)
                        and (orientation: landscape) {
                            opacity: 1;
                            filter: none;
                            height: calc(100vh - 100px);
                        }

                        @include breakpoint(1400px) {
                            height: calc(100vh - 142px);
                        }
                    }
                    .overlay__content {
                        @include breakpoint(medium only) {
                            opacity: 1;
                        }
                        @media only screen
                        and (min-device-width: 768px)
                        and (max-device-width: 1024px)
                        and (orientation: landscape) {
                            height: calc(100vh - 122px);
                            opacity: 1;
                        }

                        @include breakpoint(large up) {
                            height: calc(100vh - 142px);
                        }

                        .overlay__content__inner {
                            @include breakpoint(large up) {
                                padding-top: rem-calc(100);
                            }
                            .upper {
                                .category {
                                    font-size: rem-calc(12);
                                }
                            }
                            .middle {
                                .overlay__text {
                                    padding-top: rem-calc(10);
                                    font-size: rem-calc(45);
                                    line-height: rem-calc(50);
                                }
                            }
                            .lower {
                                .author {
                                    padding-top: 0;
                                    @include breakpoint(medium only) {
                                        font-size: rem-calc(30);
                                        padding-bottom: 0;
                                    }
                                }
                                .abstract {
                                    line-height: rem-calc(32);
                                    padding-bottom: rem-calc(60);

                                    @include breakpoint(medium only) {
                                        font-size: rem-calc(24);
                                        padding-bottom: rem-calc(20);
                                    }
                                }
                            }
                        }
                    }
                }
            }

            .blog__button__wrapper {
                .blog__button {
                    text-transform: uppercase;
                    color: white;
                    letter-spacing: 0.375rem;
                    font-size: 0.875rem;
                    font-family: 'ButlerStencil-ExtraBold';
                    background-color: transparent;
                    border: 1px solid white;
                    //border-width: 2px 1px 0.5px 0px;
                    padding: rem-calc(16) rem-calc(22) rem-calc(12) rem-calc(28);
                }
            }

            &:hover, &.hover  {
                .image__item {
                    filter: grayscale(100%);
                }

                .image__overlay__wrapper {
                    display: block;
                    opacity: 1;
                    //transition: fade 500ms;

                    .image__overlay {
                        opacity: 1;
                        filter: none;
                    }

                    .overlay__content {
                        opacity: 1;
                        -webkit-transition-delay: 0.4s; /* Safari */
                        transition-delay: 0.4s;
                        .overlay__content__inner {
                            .border {
                                height: 100%;
                                -webkit-transition-delay: 0.8s; /* Safari */
                                transition-delay: 0.8s;
                            }
                        }
                    }
                }
            }
        }

        .right {
            padding-left: 0;
            padding-right: 0;

            .blog__content {
                //padding: rem-calc(35);

                //@include breakpoint(small only) {
                //    padding: rem-calc(20) rem-calc(10);
                //}
                //
                //@include breakpoint(medium up) {
                //    padding: rem-calc(35) rem-calc(50) 0 rem-calc(50);
                //}
                //
                //@media only screen
                //and (min-device-width: 768px)
                //and (max-device-width: 1024px)
                //and (orientation: landscape) {
                //    padding: rem-calc(20) rem-calc(10) 0 rem-calc(10);
                //}

                .sub__heading {
                    padding: rem-calc(35) rem-calc(50) 0 rem-calc(80);

                    @media only screen
                    and (min-device-width: 768px)
                    and (max-device-width: 1024px) {
                        padding-left: rem-calc(65);
                        padding-right: rem-calc(65);
                    }

                    @media only screen
                    and (min-device-width: 768px)
                    and (max-device-width: 1024px)
                    and (orientation: landscape) {
                        padding-left: rem-calc(40);
                        padding-right: rem-calc(40);
                    }

                    @include breakpoint(small only) {
                        padding-left: rem-calc(20);
                        padding-right: rem-calc(20);
                    }

                    .lead__text {
                        font-family: 'Gotham-Medium';
                        font-size: rem-calc(45);
                        color: #ddd;
                        letter-spacing: 0;
                        line-height: 1.2;

                        @include breakpoint(small only) {
                            font-size: rem-calc(20);
                        }

                        @include breakpoint(medium up) {
                            font-size: rem-calc(40);
                        }

                        @media only screen and (min-device-width: 768px) and (max-device-width: 1024px) {
                            font-size: rem-calc(32);
                        }
                    }
                }
                .dash {
                    width: rem-calc(35);
                    height: rem-calc(1);
                    background: $yellow;
                    margin-top: rem-calc(35);
                    margin-bottom: rem-calc(60);
                    margin-left: rem-calc(80);

                    @include breakpoint(medium up) {
                        width: rem-calc(45);
                    }
                    @include breakpoint(small only) {
                        margin-top: rem-calc(15);
                        margin-left: rem-calc(20);
                        margin-bottom: rem-calc(30);
                    }

                    @media only screen
                    and (min-device-width: 768px)
                    and (max-device-width: 1024px) {
                        margin-left: rem-calc(65);
                    }

                    @media only screen
                    and (min-device-width: 768px)
                    and (max-device-width: 1024px)
                    and (orientation: landscape) {
                        margin-left: rem-calc(40);
                    }
                }
                .body {
                    p {
                        font-family: 'FreightTextBookItalic';
                        font-size: rem-calc(24);
                        color: $gray;
                        letter-spacing: rem-calc(1);
                        line-height: rem-calc(27);
                        word-spacing: rem-calc(2);
                        padding-bottom: rem-calc(30);
                        padding-left: rem-calc(80);
                        padding-right: rem-calc(50);

                        @include breakpoint(small only) {
                            font-size: rem-calc(16);
                            line-height: rem-calc(20);
                            padding-left: rem-calc(20);
                            padding-right: rem-calc(20);
                            margin-bottom: 0;
                        }

                        @include breakpoint(medium up) {
                            line-height: rem-calc(32);
                            //margin-bottom: rem-calc(50);
                        }

                        @media only screen
                        and (min-device-width: 768px)
                        and (max-device-width: 1024px) {
                            padding-left: rem-calc(65);
                            padding-right: rem-calc(65);
                        }

                        @media only screen
                        and (min-device-width: 768px)
                        and (max-device-width: 1024px)
                        and (orientation: landscape) {
                            padding-left: rem-calc(40);
                            padding-right: rem-calc(40);
                        }
                    }

                    .blog__image__item {
                        padding-bottom: rem-calc(46);
                    }
                    img {
                        max-width: none;
                        width: 100%;

                        //@include breakpoint(medium only) {
                        //    max-width: 121%;
                        //    margin-left: rem-calc(-65);
                        //}

                        //@include breakpoint(small only) {
                        //    max-width: 112%;
                        //    margin-left: rem-calc(-20);
                        //}

                        //@media only screen
                        //and (min-device-width : 768px)
                        //and (max-device-width : 1024px)
                        //and (orientation : landscape) {
                        //    max-width: 116%;
                        //    margin-left: rem-calc(-45);
                        //}

                        @include breakpoint(1400px) {
                            //min-width: 1024px;
                        }
                    }
                }
            }
            .related__blog__post {
                position: relative;
                padding: 0 rem-calc(30) rem-calc(35) rem-calc(30);

                @include breakpoint(small only) {
                    padding-left: rem-calc(5);
                    padding-right: rem-calc(5);
                }

                .inner {
                    display: inline-block;
                    text-align: center;
                    a {
                        color: #25354F;
                        span:nth-child(1) {
                            text-transform: lowercase;
                            font-family: 'FreightTextMediumItalic';
                            font-size: rem-calc(16);
                            letter-spacing: 1px;

                            @include breakpoint(small only) {
                                font-size: rem-calc(12);
                            }
                        }
                        span:last-child {
                            font-family: 'Gotham-Medium';
                            font-size: 0.875rem;
                            letter-spacing: 0.375rem;

                            @include breakpoint(small only) {
                                font-size: rem-calc(8);
                            }
                        }
                    }

                    .back_title {
                        font-family: 'ButlerStencil-ExtraBold';
                        letter-spacing: rem-calc(6);
                        text-transform: uppercase;
                        font-size: rem-calc(15);
                        color: $blue;
                        cursor: pointer;
                    }

                    .dash {
                        width: rem-calc(45);
                        height: rem-calc(1);
                        background-color: $blue;
                        margin: rem-calc(25) auto 0 auto;
                    }
                }

                .down__arrow__wrapper {
                    .down__arrow__outer {
                        position: absolute;
                        top: rem-calc(-69);
                        left: calc(50% - 18px);
                        -webkit-transform: rotate(180deg);
                        transform: rotate(180deg);
                        z-index: 1;
                    }
                }
            }
        }
    }

    #left__blog__container.has__stuck {
        top: 142px !important;
        @media only screen
        and (min-device-width: 768px)
        and (max-device-width: 1024px)
        and (orientation: landscape) {
            top: 122px !important;
        }
    }

    .horizontal__rule__container {
        padding: 0 rem-calc(50);
        background-color: $lighter-gray;
        .horizontal__rule {
            width: 100%;
            height: 1px;
            background-color: $gray;
        }
    }

    .footer.gray {
        background-color: $lighter-gray;
    }
}