.search__overlay {
    padding-bottom: 0px;

    .search__overlay__inner {
        padding-bottom: 0px;
        // border-bottom:  none;
        overflow-y:     auto;

        .search__overlay__content {
            padding-bottom: 0px;
            position:       fixed;
            pointer-events: none;
            width:          calc(100% - 100px);
            z-index:        99;

            &.active {
                margin: -29px;
                width:          calc(100% - 62px) !important;
                padding: 50px;
                background: -moz-linear-gradient(top, rgba(255,255,255,1) 0%, rgba(255,255,255,0) 100%); /* FF3.6-15 */
                background: -webkit-linear-gradient(top, rgba(255,255,255,1) 0%,rgba(255,255,255,0) 100%); /* Chrome10-25,Safari5.1-6 */
                background: linear-gradient(to bottom, rgba(255,255,255,1) 0%,rgba(255,255,255,0) 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
                filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#ffffff', endColorstr='#00ffffff',GradientType=0 ); /* IE6-9 */

                .close__icon__wrapper {
                    top: rem-calc(35);
                }
            }

            .searchContent {
                display: block;
                z-index: 99;
            }

            .search__close {
                pointer-events: all;
            }

            input[type="text"] {
                pointer-events: all;
                @include breakpoint(large up) {
                    font-size: rem-calc(70);
                    height:    rem-calc(100);
                }
            }

            .close__icon__wrapper {
                top:    rem-calc(5);
                right:  0px;
                margin: 0px;
            }

            .down__arrow__wrapper {
                position: fixed;
                left:     calc(50% - 14px);
                bottom:   rem-calc(60);
                z-index:  100;
            }

            .results__text {
                text-transform: uppercase;
                font-family:    'Gotham-Medium';
                font-size:      rem-calc(10);
                letter-spacing: rem-calc(4);
                color:          $yellow;

                @include breakpoint(large up) {
                    margin-top: rem-calc(50);
                }

                .num__results {
                    display:      inline-block;
                    font-family:  'FreightTextBookItalic';
                    font-size:    rem-calc(22);
                    margin-right: rem-calc(8);
                }
            }

        }

        .results__list {
            padding-top: 400px;
            position:    relative;
            width:       100%;
            // bottom:     rem-calc(-5);
            // overflow-y: scroll;
            //z-index: -1;
            // height:     rem-calc(380);

            @include breakpoint(medium only) {
                // height: rem-calc(700);
            }
        }
    }
}

/*
.search_overlay
{

    position: fixed;
    z-index: 100;
    width: 100%;
    height: 100%;
    background-color: rgba(255,255,255,0.95);

    .up-arrow
    {
        top: 410px;
        z-index: 1;
    }

    .search_overlay_inner
    {
        border: 2px solid $orange;
        margin: 1.15rem;

        .search-close
        {
            float: right;
        }

        .img
        {
            height: rem-calc(560);
        }

        .search_overlay_input
        {
            &:focus
            {
                transition: none;
                box-shadow: none;
                background-color: transparent;
                border: 0px   
            }

           transition: none;
           box-shadow: none;
           background-color: transparent;
           border: 0px;
           color: $ash-gray;
           font-size: rem-calc(63);
           font-family: 'Gotham-Medium';
           //border:1px solid $orange;
           height: rem-calc(100);

        }

        .search_titlebar
        {
            padding-bottom: rem-calc(80);
        }

        .crossed 
        {
                position:absolute;
                top: -2.4rem;
                left: 13.4rem;
                display: inline-block;
        }

        .crossed:after 
        {
            content:"";
            position:absolute;
            top: rem-calc(-33.6);
            left:rem-calc(25.6);
            border-top: 1.5px solid $yellow;
            width:rem-calc(92.8);
            transform: rotate(122deg);
            transform-origin: 0% 0%;
        }

        .search_overlay_content
        {
                margin: 1.825rem;
                margin-top: 3.9rem;
                margin-left: 2.3rem;
        }
            
        .search_overlay_title
        {
            font-family: 'ButlerStencil-ExtraBold';
            font-size: rem-calc(35);
            color: $orange;
            text-transform: uppercase;
            letter-spacing: rem-calc(4);
            padding-right: 4.925rem;
            display: inline-block;
        }

        .search_overlay_description
        {
            font-family: 'FreightTextBookItalic';
            color: $blue;
            font-size: rem-calc(19);
            padding-top: rem-calc(10);
            display: inline-block;
        }

        .search_result_number
        {
            font-family: 'Gotham-Medium';
            font-size: rem-calc(8.75);
            font-weight: bold;
            text-transform: uppercase;
            letter-spacing: rem-calc(4);
            color: $orange;
            padding-bottom: rem-calc(155);
            padding-left: rem-calc(20);
        }

        .result
        {
            font-size: rem-calc(19.06);
            font-family: 'FreightTextMediumItalic';
            color: $orange;
        }
    }

}
*/