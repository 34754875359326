.image__item__wrapper {
    position: relative;
    margin-bottom: rem-calc(10);

    opacity: 0.8;

    &.no__filter
    {
        opacity: 1;
    }

    @include breakpoint(medium up) {
        padding: 0px rem-calc(5);
    }

    @include breakpoint(small only) {
        padding: 0px rem-calc(5);
    }

    .image__item {
        background-size: cover;
        background-position: 50%;
        background-repeat: no-repeat;
        height: rem-calc(280);
        filter: grayscale(100%);

        @include breakpoint(medium up) {
            height: rem-calc(380);
        }

        @include breakpoint(1400px) {
            height: rem-calc(500);
        }
    }

    .image__overlay__wrapper {
        opacity: 0;
        transition: opacity 0.3s linear;
        width: 100%;
        height: 0px;
        position: relative;
        bottom: rem-calc(280);
        //z-index: -1;

        @include breakpoint(small only)
        {
            opacity: 1;
        }

        @include breakpoint(medium up) {
            bottom: rem-calc(380);
        }

        @include breakpoint(1400px) {
            bottom: rem-calc(500);
        }

        .image__overlay {
            background: rgba(253, 189, 50, 0.5);
            //background: $yellow;

            @include breakpoint(small only)
            {
                opacity: 1;
                filter: none;
            }
        }

        .overlay__content {
            opacity: 0;
            position: absolute;
            top: 0px;
            width: 100%;
            height: rem-calc(280);
            background: rgba(0, 0, 0, 0.1);
            padding: rem-calc(20);
            transition: opacity 0.3s linear;

            @include breakpoint(small only)
            {
                opacity: 1;
            }

            @include breakpoint(medium up) {
                height: rem-calc(380);
            }

            @include breakpoint(1400px) {
                height: rem-calc(500);
            }

            .overlay__content__inner {
                color: $white;
                font-family: 'Gotham-Medium';
                font-size: rem-calc(16);
                margin-left: rem-calc(14);
                padding-left: rem-calc(20);
                position: relative;
                margin-top: rem-calc(10);
                margin: 50px 10px 100px 10px;
                // border-left:  1px solid $white;
                margin: 0px;
                height: 100%;

                padding-top: rem-calc(20);
                overflow: auto;

                @include breakpoint(large up) {
                    max-width: rem-calc(450);
                    padding-top: rem-calc(50);
                }

                .border {
                    position: absolute;
                    display: block;
                    left: 0;
                    top: 0;
                    margin: 0 auto;
                    height: 0%;
                    background-color: #ffffff;
                    width: 1px;
                    transition: height 1.25s ease;

                    @include breakpoint(small only)
                    {
                        height: 100%;
                        -webkit-transition-delay: 0.8s; /* Safari */
                        transition-delay: 0.8s;
                    }
                }

                .upper {
                    font-size: rem-calc(10);

                    @include breakpoint(medium up) {
                        font-size: rem-calc(14);
                    }

                    .project__number {
                        font-family: 'ButlerStencil-ExtraBold';
                        font-size: rem-calc(14);
                        letter-spacing: 4px;
                        padding-bottom: 15px;
                    }

                    .category {
                        font-family: 'Gotham-Medium';
                        font-size: rem-calc(10);
                        text-transform: uppercase;
                        letter-spacing: rem-calc(3);
                    }

                    .slashes {
                        font-size: rem-calc(10);
                        margin-top: rem-calc(8);
                    }

                    span:last-child {
                        position: relative;
                        right: rem-calc(2);
                    }
                }

                .middle {
                    .overlay__text {
                        font-size: rem-calc(24);
                        //font-family: 'Gotham-Bold';
                        font-weight: bold;
                        line-height: rem-calc(26);
                        padding: rem-calc(15) 0px;

                        @include breakpoint(medium up) {
                            font-size: rem-calc(18);
                            line-height: rem-calc(40);
                            padding: rem-calc(20) 0px;
                        }

                        @include breakpoint(large only) {
                            font-size: rem-calc(18);
                            line-height: rem-calc(40);
                            padding: rem-calc(20) 0px;
                        }

                        @include breakpoint(1400px) {
                            font-size: rem-calc(38);
                            line-height: rem-calc(40);
                            padding: rem-calc(20) 0px;
                        }
                    }
                }

                .lower {
                    .overlay__subtext {
                        font-family: 'FreightTextMediumItalic';
                        font-size: rem-calc(20);
                        line-height: rem-calc(24);

                        @include breakpoint(medium up) {
                            font-size: 100%;
                            padding: rem-calc(20) 0px;
                        }

                        @include breakpoint(large up) {
                            font-size: 100%;
                            padding: rem-calc(20) 0px;
                        }

                        @include breakpoint(1400px) {
                            font-size: rem-calc(24);
                            line-height: rem-calc(28);
                        }

                    }
                }
            }

            //hide scroll bar
            ::-webkit-scrollbar {
                display: none;
            }
        }
    }

    &.case__study {

        .image__item {
            filter: none;
        }

        @include breakpoint(small only)
        {
            opacity: 0.8;

            .image__item {
                filter: grayscale(100%);
            }

            .image__overlay__wrapper {
                display: block;
                opacity: 1;
                //transition: fade 500ms;

                .image__overlay {
                    opacity: 1;
                    filter: none;
                }

                .overlay__content {
                    opacity: 1;
                    -webkit-transition-delay: 0.4s; /* Safari */
                    transition-delay: 0.4s;

                    .overlay__content__inner {
                        .border {
                            height: 100%;
                            -webkit-transition-delay: 0.8s; /* Safari */
                            transition-delay: 0.8s;
                        }
                    }

                }
            }
        }


        &:hover {

            opacity: unset;

            .image__item {
                filter: grayscale(100%);
            }

            .image__overlay__wrapper {
                display: block;
                opacity: 1;
                //transition: fade 500ms;

                .image__overlay {
                    opacity: 1;
                    filter: none;
                }

                .overlay__content {
                    opacity: 1;
                    -webkit-transition-delay: 0.4s; /* Safari */
                    transition-delay: 0.4s;

                    .overlay__content__inner {
                        .border {
                            height: 100%;
                            -webkit-transition-delay: 0.8s; /* Safari */
                            transition-delay: 0.8s;
                        }
                    }

                }
            }
        }
    }

    &.project__item {
        &:hover {

            opacity: unset;

            .image__item {
                filter: grayscale(100%);
            }

            .image__overlay__wrapper {
                display: block;
                opacity: 1;
                //transition: fade 500ms;

                .image__overlay {
                    opacity: 1;
                    filter: none;
                }

                .overlay__content {
                    opacity: 1;
                    -webkit-transition-delay: 0.4s; /* Safari */
                    transition-delay: 0.4s;

                    .overlay__content__inner {
                        .border {
                            height: 100%;
                            -webkit-transition-delay: 0.8s; /* Safari */
                            transition-delay: 0.8s;
                        }
                    }

                }
            }
        }
    }
}
