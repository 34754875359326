.culture 
{
    .page__label::after
    {
        content: "Our Culture";
    }

    .text__block 
    {
        z-index: 2;

        @include breakpoint(small only)
        {
            max-width: rem-calc(400);
            margin: auto;
        }
        
        .text
        {
            &.clean 
            {
                @include breakpoint(1300px)
                {
                    font-size: rem-calc(14);
                    line-height: rem-calc(42);
                    letter-spacing: rem-calc(6);
                }
            }
        }
    }

    .image__block
    {
        $height-mobile: 250;
        $height-mobile: 320;

        height: rem-calc($height-mobile);

        //background: #eee;
        background-size: cover;
        background-position: 50%;
        background-repeat: no-repeat;

        @include breakpoint(medium up)
        {
            height: rem-calc(320);
        }

        @include breakpoint(large up)
        {
            height: rem-calc(500);
            margin-left: rem-calc(50);
        }

        @include breakpoint(1400px)
        {
            height: rem-calc(700);
        }

        .inner__text
        {
            display: none;
            text-transform: uppercase;
            text-align: center;
            font-family: 'Gotham-Bold';
            font-size: rem-calc(60);
            letter-spacing: rem-calc(4);
            color: $hot-pink;
            
            position: relative;
            //position text 50% from top of parent minus half its height
            top: calc(50% - 45px);
            padding-left: rem-calc(4);
        }
    }
    
    #vision,
    #values 
    {
        .text__block 
        {
            @include breakpoint(medium only)
            {
                padding-right: rem-calc(20);
            }
        }
    }

    #vision 
    {
        @include breakpoint(large up)
        {
            //margin-top: rem-calc(130);
            margin-top: rem-calc(142);
        }
    }

    #values 
    {
        @include breakpoint(medium up)
        {
            position: relative;
            bottom: rem-calc(20);
        }

        @include breakpoint(large up)
        {
            bottom: rem-calc(50);
        }

        .down__arrow__wrapper
        {
            .down__arrow__outer
            {
                position: absolute;
                right: rem-calc(50);
                bottom: rem-calc(-16);
            }
        }
    }

    .side__labels
    {
        display: none;
        height: 0px;
        position: relative;

        @include breakpoint(large up)
        {
            display: block;

            .side__label
            {
                position: absolute;
                top: rem-calc(100);
                text-transform: uppercase;
                font-family: 'ButlerStencil-ExtraBold';
                font-size: rem-calc(14);
                letter-spacing: rem-calc(12);
                color: $ash-gray;

                &.label-1
                {
                    // top: calc(50% - 30px);
                    left: rem-calc(18);
                    //transform: rotate(-90deg);
                }

                &.label-2
                {
                    right: rem-calc(18);
                    //transform: rotate(90deg);
                }
            }
        }
    }

    //.side__labels
    //{
    //    display: none;
    //
    //    @include breakpoint(large up)
    //    {
    //        display: flex;
    //        height: 100%;
    //        align-items: center;
    //        position: absolute;
    //        top: 0;
    //
    //        &.left
    //        {
    //            left: rem-calc(35);
    //        }
    //
    //        &.right
    //        {
    //            right: rem-calc(35);
    //        }
    //
    //        .side__label
    //        {
    //            color: $ash-gray;
    //            font-family: 'ButlerStencil-ExtraBold';
    //            font-size: rem-calc(14);
    //            letter-spacing: rem-calc(12);
    //            text-transform: uppercase;
    //            text-orientation: mixed;
    //            writing-mode: vertical-lr;
    //        }
    //    }
    //}




    #our__people
    {
        @include breakpoint(medium up)
        {
            padding-bottom: rem-calc(50);
            //padding: rem-calc(50) 0px;
            // border-bottom: 1px solid $yellow;
        }

        @include breakpoint(large up)
        {
            //padding-bottom: rem-calc(150);
        }

        .slash 
        {
            font-size: rem-calc(12);
            margin: rem-calc(10) 0px;
        }
    }

    .people
    {
        .text__block__wrapper
        {
            @include breakpoint(medium up)
            {
                padding-left: rem-calc(30);
            }
            
            .text__block 
            {
                margin: auto;
                
                @include breakpoint(medium up) {
                    padding-top: 170px;
                }

                @include breakpoint(large up)
                {
                    max-width: none;
                }

                .text
                {
                    //padding-top: rem-calc(50);

                    @include breakpoint(small only)
                    {
                        padding-top: rem-calc(70);
                    }
                }

                .dash 
                {
                    width: rem-calc(50);
                    height: rem-calc(1);
                    margin: 0px;
                    background: $yellow;
                }

                .underlay
                {
                    @include breakpoint(small only)
                    {
                        font-size: rem-calc(60);    
                    }
                }
            }
        }
    }
    
    .peopleLeft
    {
        @include breakpoint(medium only)
        {
            margin-bottom: rem-calc(50);
        }
    }
    
    .peopleRight
    {
        @include breakpoint(large up)
        {
            position: relative;
            bottom: rem-calc(50);
        }
    }

    #vision,
    #values,
    .people
    {
        .text__block
        {
            @include breakpoint(large up)
            {
                max-width: rem-calc(600) !important;
                margin: auto;   
            }
        }
    }

    #culture__main
    {
        margin-top: rem-calc(40);
        padding-bottom: rem-calc(20);

        @include breakpoint(medium up)
        {
            height: auto;
            margin: 0px;
            //margin-top: rem-calc(100);
            padding-bottom: rem-calc(50);
        }

        @include breakpoint(large up)
        {
            padding-bottom: 0px;
        }

        .text__block__wrapper
        {
            display: block;
            text-align: center;

            .text__block
            {
                &#culture__text__block
                {
                    text-align: left;
                    padding: 0px;
                    padding-left: rem-calc(50);

                    @include breakpoint(small only)
                    {
                        text-align: center;
                        padding-left: 0px;
                    }

                    .underlay__wrapper  
                    {
                        height: auto;

                        .underlay 
                        {
                            position: relative;
                            top: initial;
                            right: initial;
                            bottom: initial;
                            left: initial;
                            z-index: 1;

                            @include breakpoint(small only)
                            {
                                font-size: rem-calc(60);
                                margin-bottom: rem-calc(-52);
                            }

                            @include breakpoint(medium up)
                            {
                                font-size: rem-calc(80);
                                top: rem-calc(70);
                            }
                        }
                    }
                }
            }
        }

        .lists__container
        {
            position: relative;
            text-align: center;
            padding-top: rem-calc(70);
            padding-left: rem-calc(20);
            background-color: #eee;
            padding-bottom: rem-calc(30);

            @include breakpoint(large up)
            {
                //display: block;
                text-align: left;
                vertical-align: top;
                //padding-top: rem-calc(30);
                //padding-left: rem-calc(50);
                padding: rem-calc(100) rem-calc(20) rem-calc(60) rem-calc(50);
                width: 100%;
                height: 100%;
            }

            .list__item__wrapper
            {
                padding-right: rem-calc(20);
                padding-bottom: rem-calc(50);

                @include breakpoint(large up)
                {
                    margin: 0px;
                    
                }
                .list__item 
                {
                    padding-left: rem-calc(20);
                    text-align: left;

                    .number__wrapper
                    {
                        display: inline-block;
                        width: 0px;
                        position: absolute;

                        .number 
                        {
                            text-transform: uppercase;
                            font-family: 'ButlerStencil-ExtraBold';
                            font-size: rem-calc(12);
                            letter-spacing: rem-calc(2);
                            color: $yellow;
                            position: relative;
                            right: rem-calc(25);
                            bottom: rem-calc(10);
                        }
                    }

                    .heading 
                    {
                        display: inline-block;
                        text-transform: uppercase;
                        font-family: 'Gotham-Medium';
                        font-size: rem-calc(14);
                        letter-spacing: rem-calc(6);
                        color: $dark-blue;
                        padding-bottom: rem-calc(8);

                        @include breakpoint(medium up)
                        {
                            letter-spacing: rem-calc(0);
                        }

                        @include breakpoint(large up)
                        {
                            letter-spacing: rem-calc(0);
                        }
                        @include breakpoint(1400px)
                        {
                            letter-spacing: rem-calc(6);

                        }
                    }

                    .dash
                    {
                        width: rem-calc(35);
                        height: rem-calc(1);
                        background: $yellow;                        
                        margin-bottom: rem-calc(20);
                        margin-left: rem-calc(5);
                    }

                    .sub__list__container
                    {
                        .sub__list__item
                        {
                            position: relative;

                            .icon__wrapper 
                            {
                                display: inline-block;
                                //position: relative;
                                //right: rem-calc(25);
                                //bottom: rem-calc(5);
                                position: absolute;
                                bottom: rem-calc(15);
                                left: rem-calc(-25);

                                .icon 
                                {
                                    .line
                                    {
                                        width: rem-calc(10);
                                        height: rem-calc(1);
                                        position: absolute;
                                        background-color: $gray;
                                        
                                        &.line-1
                                        {

                                        }

                                        &.line-2
                                        {
                                            transform: rotate(90deg);
                                        }
                                    }
                                }
                            }

                            .text 
                            {
                                display: inline-block;
                                font-family: 'FreightTextBookItalic';
                                font-size: rem-calc(15);
                                color: $gray;
                            }   
                        }
                    }
                }

                &.item-3
                {
                    vertical-align: top;

                    @include breakpoint(medium only)
                    {
                        //position: absolute;
                    }

                    .text
                    {
                        display: block !important;
                    }
                }
            }
        }

        .lists__container,
        #connect
        {
            @include breakpoint(large up)
            {
                display: inline-block;
            }
        }

        #connect
        {
            position: relative;

            @include breakpoint(medium only)
            {
                padding-top: rem-calc(60);
            }

            @include breakpoint(large up)
            {
                display: block;
                width: 100%;
                height: 100%;
                padding-top: rem-calc(80);
                //border-top: 1px solid #eee;
            }

            .arrow__wrapper
            {
                height: 0px;

                .arrow 
                {
                    background: $white;
                    width: rem-calc(50);
                    height: rem-calc(50);
                    transform: rotate(45deg);
                    position: absolute;
                    top: rem-calc(-20);
                    left: calc(50% - 25px);

                    @include breakpoint(large up)
                    {
                        top: calc(50% - 25px);
                        left: rem-calc(-20);
                    }
                }
            }

            .text__block 
            {   
                position: relative;
                z-index: 1;

                @include breakpoint(small only)
                {
                    padding-top: rem-calc(50);
                }

                @include breakpoint(large up)
                {
                    padding-top: 0px;
                }

                .text 
                {
                    color: $ash-gray;
                    font-size: rem-calc(20);
                    line-height: rem-calc(34);
                    max-width: rem-calc(500);
                    margin: auto;

                    @include breakpoint(medium up)
                    {
                        font-size: rem-calc(30);
                        line-height: rem-calc(44);
                        max-width: rem-calc(700);
                    }

                    @include breakpoint(medium only)
                    {
                        color: $dark-blue
                    }

                    @include breakpoint(large up)
                    {
                        font-size: rem-calc(24);
                        line-height: rem-calc(36);
                        letter-spacing: rem-calc(2);
                        word-spacing: rem-calc(2);
                    }
                }

                .slashes
                {
                    .slash
                    {
                        font-size: rem-calc(12);
                        margin: rem-calc(10) 0px;
                    }
                }
            }

            button
            {
                text-transform: uppercase;
                color: $yellow;
                letter-spacing: rem-calc(6);
                font-size: rem-calc(14);
                font-family: 'ButlerStencil-ExtraBold';
                background-color: $dark-blue;
                //border: 1px solid $yellow;
                padding: rem-calc(18) rem-calc(22) rem-calc(12) rem-calc(28);

                &:hover 
                {
                    background-color: $yellow;
                    color: $white;
                }
            }
        }
    }
}