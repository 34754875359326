.hidden { display: none; }

body
{
    overflow-y: scroll;
    overflow-x: hidden;

    &.viewing-page-2
    {
        overflow-y: scroll;

        .scrollable
        {
            width: 100%;
            //position: relative !important;
            top: 0 !important;
            z-index: 1;
        }
    }
}

.visibility-hidden
{
    visibility: hidden;
}

html,
body,
.off-canvas-wrapper
{
    height: 100%;

    .off-canvas-content
    {
        height: 100%;
    }
}

.off-canvas-wrapper
{
    overflow: visible !important;
}

.inner-bar
{
    position: absolute;
    left: 0px;
}

.off-canvas
{
    &.is-transition-overlap
    {
        &.is-open
        {
            width: rem-calc(566);
            z-index: 100;
            overflow-x: hidden; 
            overflow-y: hidden;
            box-shadow: none;

            @include breakpoint(small only)
            {
                width: 100%
            }

        }
    }
}

button, a {
    cursor: pointer;
}

.image 
{
    position:relative;
    width:100%;
    height:100%;
}
.image .img 
{
    width:100%;
    vertical-align:top;
}

.image:after, .image:before 
{
    position:absolute;
    opacity:0;
    transition: all 0.5s;
    -webkit-transition: all 0.5s;
}

.image:after 
{
    content:'\A';
    width:100%; height:100%;
    top:0; left:0;
    background:rgba($clear-orange,0.5);
}

.image:before 
{
    content: attr(data-content);
    width:100%;
    color:#fff;
    z-index:1;
    bottom:0;
    text-align:center;
    box-sizing:border-box;
    -moz-box-sizing:border-box;
    font-family: 'Gotham-Medium';
    font-size: rem-calc(35);
    padding-bottom: rem-calc(300);
    padding-right: rem-calc(950);
    padding-left: rem-calc(50);
    padding-top: rem-calc(50);
    text-align: left;
}

.image:hover:after, .image:hover:before 
{
    opacity:1;
}

.image 
{
    .image_overlay_title, .image_overlay_title_icon, .image_overlay_subtitle, .image_overlay_text, .image_overlay_line, .image_overlay_numbering
    {
        display: none;
    }
}

.image:hover 
{
    .image_overlay_title, .image_overlay_title_icon, .image_overlay_numbering
    {
        position: absolute;
        left: 6%;
        top: 22%;
        color: $white;
        z-index: 15;
        display: inline-block;
        font-family: 'Gotham-Medium';
        font-weight: bold;
        font-size: rem-calc(8.75);
        text-transform: uppercase;
        letter-spacing: 4px;
        padding-top: 0;

        @include breakpoint(small only)
        {
            top: 5%;
            font-size: 0.546875rem;
            letter-spacing: rem-calc(2);
        }
        
        @include breakpoint(medium only)
        {
            letter-spacing: 3px;
            font-size: 0.346rem;
        }
    }

    .image_overlay_numbering
    {
        top: 18%;
        font-family: 'ButlerStencil-ExtraBold';

        @include breakpoint(small only)
        {
            top: 8%;

        }
        
        @include breakpoint(medium only)
        {

        }
    }
    
    .image_overlay_title_icon
    {
        top: 25%;

        @include breakpoint(small only)
        {
            top: 8%;

        }
        
        @include breakpoint(medium only)
        {

        }
    }

    .image_overlay_subtitle
    {
        position: absolute;
        left: 5%;
        top: 29%;
        color: $white;
        z-index: 15;
        display: inline-block;
        font-size: rem-calc(35);
        font-family: 'Gotham-Medium';
        line-height: rem-calc(40);

        @include breakpoint(small only)
        {
            top: 10%;
            font-size: 1.0875rem;
            line-height: 1.5rem;
        }
        
        @include breakpoint(medium only)
        {
            top: 30%;
            font-size: 1.18rem;
            line-height: 1.6rem;
        }
    }

    .image_overlay_text
    {
        position: absolute;
        left: 5%;
        top: 60%;
        color: $white;
        z-index: 15;
        display: inline-block;
        font-family: 'FreightTextMediumItalic';
        font-size: rem-calc(22);
        letter-spacing: rem-calc(1);
        line-height: 1.7rem;

        @include breakpoint(small only)
        {   font-size: 1.075rem;
            line-height: 1.4rem;
            top: 25%;
        }

        @include breakpoint(medium only)
        {
            line-height: 1.4rem;
            font-size: 0.875rem;
            letter-spacing: 0.0625rem;
        }
    }

    .image_overlay_line
    {
        display: inline-block;
        position: absolute;
 
        width: rem-calc(2);
        background: $white;
        margin-right: auto;
        margin-left: auto;
        margin-top: 0;
        z-index: 15;

        @include breakpoint(small only)
        {

        }
    
    }
}

.overlay_box_large
{
    width: rem-calc(680);
    height: rem-calc(515);
    position: absolute;
    top: 0;
    left: 0;

    @include breakpoint(small only)
    {

    }
        
     @include breakpoint(medium only)
    {
        height: 16.38rem;
    }
}

.long_image
{
    padding-right: 45%;

    @include breakpoint(small only)
    {
        padding-right: 65%;
    }
        
    @include breakpoint(medium only)
    {
        padding-right: 61%;
    }
}

.short_image
{
    padding-right: 45%;

    @include breakpoint(small only)
    {
        padding-right: 65%;
    }
        
    @include breakpoint(medium only)
    {
        padding-right: 65%;
    }
}

.long_bar
{
    left: 2%;
    top: 21%;
    background: $white;

    @include breakpoint(small only)
    {
        top: 5%;
        height: 32%;
    }

    @include breakpoint(medium only)
    {
        height: 53.2%;
    }

    @include breakpoint(large)
    {
        height: 49%;
    }
    
}

.short_bar
{
    left: 2%;
    top: 3%;
    height: 82%;
    background: $white;

    @include breakpoint(small only)
    {
        top: 5%;
        height: 32%;
    }

}

.show_image_bar
{
    left: 2%;
    top: 3%;
    height: 76%;
    background: $white;

    @include breakpoint(small only)
    {
        top: 5%;
        height: 32%;
    }
}

/*
.close-icon
{
    cursor: pointer;
    background-color: transparent;
    background-image: url(/img/close-icon.png);
    background-repeat: no-repeat;
    border: none;
    height: rem-calc(20);
    width: rem-calc(20);
    vertical-align: middle;
    background-size: contain;
    margin-bottom: rem-calc(4);
    margin-left: rem-calc(4);
}
*/

.up-arrow
{
   
    cursor: pointer;
    background-color: transparent;
    background-image: url(/img/up-arrow.png);
    background-repeat: no-repeat;
    border: none;
    height: 2.55rem;
    width: 2.55rem;
    vertical-align: middle;
    position: absolute;
    top: -7.0875rem;
    left: 50%;
    transform: translate(-50%, 50%);

    &:focus
    {
        outline: none;
    }
}

.filter-close, .search-close, .contact-close
{

    cursor: pointer;
    background-color: transparent;
    background-image: url(/img/filter-close.png);
    background-repeat: no-repeat;
    border: none;
    height: rem-calc(36);
    width: rem-calc(36);
    position: absolute;
    top: rem-calc(55);
    left: 93%;
    &:focus
    {
        outline: none;
    }
}

.toolbar_element_tri
{
    @include breakpoint(small only)
    {
        padding-right: 1.3125rem;
        padding-bottom: rem-calc(15);
        display: inline;
    }

    @include breakpoint(medium only)
    {
        padding-left: 3.0625rem;    
    }

    @include breakpoint(large)
    {
        padding-left: 6.7625rem;
        padding-right: rem-calc(53);
    }
}

.toolbar_element_tri:nth-child(1)
{
    @include breakpoint(medium only)
    {
        padding-left: rem-calc(23);    
    }   
}

.js-off-canvas-overlay
{
    z-index: 2;
}           