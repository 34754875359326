.slideshow__overlay
{
	background: $black;

	.slideshow__overlay__inner
	{
		border: none;

		.slideshow__overlay__content
		{
			.overlay__header
			{
				.close__icon__wrapper
				{
					.close__icon 
					{
						.line 
						{
							background-color: $yellow;
						}
					}
				}
			}

			.slideshow__container
			{
				position: absolute;
				width: 100%;
				height: 100%;
				top: 0px;
				left: 0px;

				.slideshow__overlay__slideshow
				{
					height: 100%;

					.slick-arrow 
					{
						z-index: 100;

						@include breakpoint(medium up)
						{
							&.slick-prev
							{
								left: rem-calc(60);
							}

							&.slick-next
							{
								right: rem-calc(60);
							}
						}
					}

					.slick-list
					{
						.slick-track
						{
							.slide 
							{
								width: 100vw !important;

								&:focus 
								{
									outline: none;
								}

								.slide-content
								{
									.slide__image 
									{
										width: 60%;
										height: 100vh;
										position: relative;
										margin: auto;
										
										// background-size: cover;
										background-size: 100%; // fix for 60% width
										background-repeat: no-repeat;
										background-position: 50%;

										@include breakpoint(medium down)
										{
											width: 100%;
										}

										.slide__text 
										{
											width: 100%;
											position: absolute;
											bottom: 0px;
											//opacity: 0;
											opacity: 1 !important;

											transition: opacity 0.5s linear;

											background: rgba(0, 0, 0, 0.5);
											padding: rem-calc(30) 0px;

											@include breakpoint(medium down)
											{
												opacity: 1;
											}

											p 
											{
												max-width: rem-calc(500);
												margin: auto;
												margin-bottom: 0px;
												text-transform: uppercase;
												text-align: center;
												font-family: 'Gotham-Medium';
												font-size: rem-calc(14);
												line-height: rem-calc(36);
												letter-spacing: rem-calc(6);
												color: $ash-gray;

												@include breakpoint(medium down)
												{
													font-size: rem-calc(12);
													line-height: rem-calc(26);
													letter-spacing: rem-calc(4);
													padding: 0px rem-calc(10);
												}
											}
										}
									}
								}
							}
						}
					}
				}
			}

			.info__icon
			{
				width: rem-calc(43);
				height: rem-calc(43);
				position: absolute;
				bottom: rem-calc(50);
				left: rem-calc(60);
				z-index: 2;

				border: 1px solid $white;
				border-radius: rem-calc(100);
				text-align: center;
				cursor: help;

				@include breakpoint(medium down)
				{
					display: none;
				}

				&:hover
				{
					border-color: $yellow;

					.inner
					{
						color: $yellow;
					}

					+.slideshow__container
					{
						.slide__text
						{
							opacity: 1 !important;
						}
					}
				}

				.inner 
				{
					color: $white;
					font-size: rem-calc(26);
					font-family: 'Gotham-Medium';
				}
			}
		}
	}
}