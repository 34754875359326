.slash__wrapper
{
    display: inline-block;
    height: 0px;

    .slash 
    {
        background-color: $yellow;
        width: rem-calc(1);
        height: rem-calc(50);
        transform: rotate(25deg);
        position: relative;
        bottom: rem-calc(35);

        @include breakpoint(large up)
        {
            height: rem-calc(90);
            bottom: rem-calc(60);
        }
    }
}