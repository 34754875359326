.case__study {
    .makeshift__apostrophe {
        display:   inline-block;
        transform: rotate(180deg);
        position:  relative;
        bottom:    rem-calc(20);
    }

    .arrow__wrapper {
        height: 0px;

        .arrow {
            background: $white;
            width:      rem-calc(50);
            height:     rem-calc(50);
            transform:  rotate(45deg);
            position:   absolute;
        }
    }

    #casestudy__banner {
        position: relative;
        overflow: hidden;

        &.fixed {
            @include breakpoint(large) {
                width:    100%;
                height:   rem-calc(500);
                position: fixed;
                top:      rem-calc(142);
            }
        }

        .background__wrapper {
            .background {
                width:               100%;
                height:              rem-calc(500);
                background-size:     cover;
                background-position: 50%;
                background-repeat:   no-repeat;
            }
        }
    }

    .headings__wrapper {
        height:   0px;
        //position: absolute;
        //bottom:   rem-calc(48);
        // position: fixed;
        position: relative;
        top:      rem-calc(-8);

        /*
        @include breakpoint(large up) {
            bottom: rem-calc(110);
        }
        */

        @include breakpoint(medium only) {
            // position: fixed;
            top:     rem-calc(-64);
            z-index: 0;
        }

        @include breakpoint(large up) {
            // position: fixed;
            // top: rem-calc(532);
            top:     rem-calc(-110);
            z-index: 0;
        }

        .heading {
            text-transform: uppercase;
            font-family:    'ButlerStencil-ExtraBold';
            color:          $white;
            margin:         0px;
            padding-left:   rem-calc(5);

            @include breakpoint(small only) {
                font-size:   rem-calc(40);
                line-height: 0px;
            }

            @include breakpoint(medium up) {
                font-size: rem-calc(70);
            }

            @include breakpoint(large up) {
                font-size:      rem-calc(120);
                letter-spacing: rem-calc(20);
            }
        }

        .sub__heading {
            display: none;

            @include breakpoint(large up) {
                display:        block;
                text-transform: uppercase;
                font-family:    'Gotham-Medium';
                font-size:      rem-calc(12);
                letter-spacing: rem-calc(6);
                color:          $white;
                position:       absolute;
                //bottom:         rem-calc(-12);
                bottom:         rem-calc(-5);
                //left:           rem-calc(50);
                left:           rem-calc(100);
            }
        }
    }

    //z-index for everything minus banner is shifted up to ensure underlay title doesn't show
    .page__content {
        position: relative;
        z-index:  1;

        @include breakpoint(large) {
            &.fixed {
                margin-top: rem-calc(642);
                background: $white;
            }
        }
    }

    #casestudy__overview {
        //margin-bottom: rem-calc(50);
        margin-top: rem-calc(40);

        @include breakpoint(medium only) {
            // margin-bottom: rem-calc(60);
        }

        @include breakpoint(large up) {
            // margin-bottom: rem-calc(80);
            margin-top: 0px;
        }

        .overview__slideshow {

            margin-bottom: 0px;

            @include breakpoint(large up) {
                // margin-top: rem-calc(200);
            }

            .slide {
                .slide-content {
                    .slide__image {
                        width:               100%;
                        height:              rem-calc(200);
                        background-size:     contain;
                        background-color:  #fff;
                        background-position: 50%;
                        background-repeat:   no-repeat;
                        //margin-bottom:       rem-calc(10);

                        @include breakpoint(medium up) {
                            height: rem-calc(500);
                        }
                    }
                }
            }

            .slick-dots {

                bottom:  rem-calc(30);
                z-index: 1;

                button {
                    width:         rem-calc(10);
                    height:        rem-calc(10);
                    border:        1px solid $yellow;
                    border-radius: rem-calc(100);
                    padding:       0px;

                    &::before {
                        color: transparent;
                    }
                }

                .slick-active {
                    button {
                        background: $yellow;
                    }
                }
            }
        }

        .location__details
        {
            @include breakpoint(medium down)
            {
                padding-left: rem-calc(50);
            }
        }
    }

    #casestudy__features {
        @include breakpoint(medium only) {
            border-top: 1px solid $yellow;
        }

        .feature__item {
            height:     rem-calc(375);
            background: $white;
            position:   relative;

            @include breakpoint(1600px) {
                height: rem-calc(500);
            }

            &.text__item {
                &.quote {
                    border-top: 1px solid $yellow;

                    @include breakpoint(medium only) {
                        border-top: none;
                    }

                    .arrow__wrapper {
                        .arrow {
                            top:     rem-calc(50);
                            left:    initial;
                            right:   rem-calc(-20);
                            z-index: 2;
                        }
                    }

                    .inner__text {
                        @include breakpoint(1600px) {
                            top: calc(50% - 80px);
                        }
                    }
                }

                .inner__text {
                    display:  inline-block;
                    width:    100%;
                    position: relative;
                    top:      rem-calc(100);

                    .heading {
                        text-transform: uppercase;
                        text-align:     center;
                        font-family:    'ButlerStencil-ExtraBold';
                        font-size:      rem-calc(16);
                        letter-spacing: rem-calc(6);
                        margin-bottom:  rem-calc(20);
                        color:          $gray;
                    }

                    .quote,
                    .details__description {
                        font-family: 'FreightTextBookItalic';
                        font-size:   rem-calc(26);
                        text-align:  center;
                        color:       $blue;
                        padding:     0px rem-calc(20);
                        max-width:   rem-calc(450);
                        margin:      auto;
                    }

                    .dash {
                        width:      rem-calc(50);
                        height:     rem-calc(1);
                        margin:     auto;
                        margin-top: rem-calc(20);
                        background: $yellow;
                    }

                    .slashes {
                        text-align:    center;
                        margin-bottom: rem-calc(15);

                        span {
                            font-size:      rem-calc(12);
                            letter-spacing: rem-calc(4);
                            color:          $yellow;
                        }
                    }

                    .author,
                    .top__text {
                        text-transform: uppercase;
                        text-align:     center;
                        font-size:      rem-calc(10);
                        letter-spacing: rem-calc(5);
                        color:          $yellow;
                        margin-top:     rem-calc(40);

                        &.top__text {
                            //font-family:   'Gotham-Bold';
                            margin-bottom: 0px;
                        }
                    }
                }

                .arrow__wrapper {
                    .arrow {
                        left:   calc(50% - 25px);
                        bottom: rem-calc(-20);

                        @include breakpoint(medium up) {
                            left:   rem-calc(-20);
                            bottom: rem-calc(160);
                        }
                    }
                }
            }

            &.details {
                .inner__text {
                    top: rem-calc(25);
                    z-index: 1;

                    @include breakpoint(small only) {
                        top: 0px;
                    }

                    @include breakpoint(1600px) {
                        top: rem-calc(100);
                    }

                    .dash {
                        margin-bottom: rem-calc(25);
                    }

                    .details__description {
                        color: $ash-gray;
                        overflow: auto;
                        max-height: rem-calc(185);
                    }
                }

                .arrow__wrapper {
                    .arrow {
                        bottom: calc(50% - 25px);

                        @include breakpoint(small only) {
                            bottom: rem-calc(-20);
                        }
                    }
                }

                .sticker__wrapper {

                    position: static;

                    .sticker {
                        position: absolute;
                        bottom:   rem-calc(-60);
                        left:     calc(50% - 60px);
                        z-index:  1;
                    }
                }
            }

            &.image__item {
                background-size:     cover;
                background-position: 50%;
                background-repeat:   no-repeat;

                &.grey
                {
                    -webkit-filter: grayscale(100%);
                    filter: grayscale(100%);
                }

                .overlay__wrapper {
                    height: 0px;

                    .overlay {
                        height:     rem-calc(375);
                        top:        0px;

                        //background: $yellow;
                        //opacity:    0.5;
                        //filter:     saturate(600%);

                        @include breakpoint(1600px) {
                            height: rem-calc(500);
                        }
                    }
                }
            }

            &.location__item {
                background: #eeeeee;

                .location__details {
                    overflow: hidden;
                    position: relative;
                    top:      rem-calc(40);
                    left:     rem-calc(60);

                    @include breakpoint(medium down)
                    {
                        left:     rem-calc(30);
                        max-height: rem-calc(335);
                        overflow: auto;
                    }

                    .size {
                        .text {
                            font-family:    'Gotham-Medium';
                            font-size:      rem-calc(10);
                            text-transform: uppercase;
                            letter-spacing: rem-calc(2);

                            span {
                                font-size:   rem-calc(18);
                                font-family: 'FreightTextBookItalic';
                                margin-left: rem-calc(5);

                                sup {
                                    text-transform: lowercase;
                                }
                            }
                        }
                    }
                }

                .year__label__wrapper {
                    height: 0px;

                    .year__label {
                        text-align:  right;
                        font-family: 'ButlerStencil-ExtraBold';
                        font-size:   rem-calc(90);
                        color:       $white;
                        position:    absolute;
                        bottom:      rem-calc(-55) !important;
                        right:       rem-calc(30);
                    }
                }

                @include breakpoint(medium only) {
                    .arrow__wrapper {
                        display: none !important;
                    }
                }
            }
        }
    }

    #casestudy__development {
        position:       relative;
        padding-bottom: rem-calc(50);
        text-align:     center;

        @include breakpoint(large up) {
            background: #eeeeee;
        }

        .side__label {
            letter-spacing: rem-calc(8);
        }

        .completion__list {
            height: 0px;

            .inner {
                position: absolute;
                top:      calc(50% - 100px);

                .line {
                    margin-bottom: rem-calc(20);
                    text-align:    left;
                    cursor:        default;

                    &:hover {
                        .dash {
                            background: $yellow;
                            width:      rem-calc(40);

                            @include breakpoint(1300px) {
                                width: rem-calc(40);
                            }
                        }

                        .category {
                            font-size: rem-calc(16);
                            color:     $yellow;
                        }

                        .value {
                            font-size: rem-calc(28);
                            color:     $yellow;
                        }
                    }

                    .dash {
                        display:      inline-block;
                        width:        rem-calc(20);
                        height:       rem-calc(1);
                        background:   $yellow;
                        position:     relative;
                        // bottom:       rem-calc(4);
                        top:          rem-calc(13);
                        margin-right: rem-calc(15);
                        transition:   width 0.2s linear;
                    }

                    .category {
                        text-transform: uppercase;
                        font-family:    'Gotham-Medium';
                        font-size:      rem-calc(10);
                        letter-spacing: rem-calc(4);
                        color:          $gray;
                        transition:     font-size 0.2s linear;
                    }

                    .value {
                        font-family:    'FreightTextBookItalic';
                        letter-spacing: rem-calc(2);
                        margin-left:    rem-calc(5);
                        color:          $light-gray;
                        transition:     font-size 0.2s linear;
                    }
                }
            }
        }

        .arrow__wrapper {
            .arrow {
                left: calc(50% - 25px);
                top:  rem-calc(-60);
            }
        }

        .inner__text {
            @include breakpoint(large up) {
                padding:  rem-calc(50) 0px;
                display:  inline-block;
                position: relative;
                left:     rem-calc(100);
            }

            @include breakpoint(1300px) {
                position: static;
            }

            .top__text {
                text-transform: uppercase;
                text-align:     center;
                //font-family:    'Gotham-Bold';
                font-size:      rem-calc(10);
                letter-spacing: rem-calc(5);
                color:          $yellow;
                margin-top:     rem-calc(40);

            }

            .slashes {
                text-align:    center;
                margin-bottom: rem-calc(15);

                span {
                    font-size:      rem-calc(12);
                    letter-spacing: rem-calc(4);
                    color:          $yellow;
                }
            }

            .text__paragraph {
                font-family: 'FreightTextBookItalic';
                font-size:   rem-calc(26);
                text-align:  center;
                color:       $blue;
                padding:     0px rem-calc(20);
                z-index:     3;

                @include breakpoint(medium up) {
                    font-size: rem-calc(30);
                }
            }

            .dash {
                width:      rem-calc(60);
                height:     rem-calc(1);
                background: $yellow;
                margin:     auto;
                position:   relative;
                top:        rem-calc(30);
            }
        }

        p {
            @include breakpoint(medium only) {
                margin-bottom: rem-calc(0);
            }

            @include breakpoint(medium up) {
                max-width: rem-calc(600);
                margin:    auto;
            }
        }
    }

    #casestudy__details {
        .details__slideshow {
            margin-bottom: 0px;

            .slide {
                .slide-content {
                    .slide__image {
                        width:               100%;
                        height:              rem-calc(375);
                        background-size:     contain;
                        background-position: 50%;
                        background-repeat:   no-repeat;
                        //margin-bottom: rem-calc(10);

                        @include breakpoint(large up) {
                            height: rem-calc(700);
                        }
                    }
                }
            }
        }

        .slick-dots {
            bottom: rem-calc(10);

            button {
                width:         rem-calc(10);
                height:        rem-calc(10);
                border:        1px solid $yellow !important;
                border-radius: rem-calc(100);
                padding:       0px;

                &:before {
                    color: transparent;
                }
            }

            .slick-active {
                button {
                    background: $yellow !important;

                    &:before {
                        color: transparent;
                    }
                }
            }
        }

        .lower {
            .filler {
                .divider__wrapper {
                    height:  0px;
                    padding: 0px rem-calc(20);

                    .divider {
                        height:     1px;
                        position:   relative;
                        top:        rem-calc(30);
                        background: $yellow;
                    }
                }

                .block {
                    padding:    rem-calc(40) 0px;
                    background: #eeeeee;
                }
            }

            .image__item {
                height:              rem-calc(375);
                background:          $white;
                position:            relative;
                background-size:     cover;
                background-position: 50%;
                background-repeat:   no-repeat;

                @include breakpoint(1600px) {
                    height: rem-calc(500);
                }

                .overlay__wrapper {
                    height: 0px;

                    .overlay {
                        height:     rem-calc(375);
                        top:        0px;
                        background: $yellow;
                        opacity:    0.5;
                        filter:     saturate(600%);

                        @include breakpoint(1600px) {
                            height: rem-calc(500);
                        }
                    }
                }
            }

            .list__items__container {
                @include breakpoint(small only) {
                    //display: none;
                    padding-left: rem-calc(50);
                }

                @include breakpoint(medium only) {
                    padding: rem-calc(80) rem-calc(20);
                }

                @include breakpoint(large up) {
                    height:     100%;
                    padding:    rem-calc(30) rem-calc(20) 0px rem-calc(20);
                    background: #eeeeee;
                }

                @include breakpoint(1200px) {
                    padding-right: rem-calc(100);
                }

                .inner {
                    @include breakpoint(1700px) {
                        max-width: rem-calc(950);
                    }

                    .list__item__wrapper {
                        display:        inline-block;
                        vertical-align: top;

                        .list__item {
                            padding-left: rem-calc(30);
                            text-align:   left;

                            .number__wrapper {
                                display: inline-block;
                                width:   0px;

                                .number {
                                    text-transform: uppercase;
                                    font-family:    'ButlerStencil-ExtraBold';
                                    font-size:      rem-calc(16);
                                    letter-spacing: rem-calc(2);
                                    color:          $yellow;
                                    position:       relative;
                                    right:          rem-calc(30);
                                    bottom:         rem-calc(5);
                                }
                            }

                            .heading {
                                display:        inline-block;
                                text-transform: uppercase;
                                font-family:    'Gotham-Medium';
                                font-size:      rem-calc(12);
                                letter-spacing: rem-calc(6);
                                color:          $dark-blue;
                                padding-bottom: rem-calc(8);
                            }

                            .dash {
                                width:         rem-calc(35);
                                height:        rem-calc(1);
                                background:    $yellow;
                                margin-bottom: rem-calc(20);
                                margin-left:   rem-calc(5);
                            }

                            .sub__list__container {
                                .sub__list__item {
                                    .icon__wrapper {
                                        display:  inline-block;
                                        position: relative;
                                        right:    rem-calc(25);
                                        bottom:   rem-calc(5);

                                        .icon {
                                            .line {
                                                width:            rem-calc(10);
                                                height:           rem-calc(1);
                                                position:         absolute;
                                                background-color: $gray;

                                                &.line-1 {

                                                }

                                                &.line-2 {
                                                    transform: rotate(90deg);
                                                }
                                            }
                                        }
                                    }

                                    .text {
                                        color: $gray;
                                    }
                                }
                            }
                        }

                        .item-1 {
                            .text {
                                font-family: 'FreightTextBookItalic';
                                font-size:   rem-calc(15);
                            }
                        }
                        .item-1,
                        .item-2,
                        .item-3 {
                            .text {
                                font-family:    'Gotham-Medium';
                                font-size:      rem-calc(8);
                                text-transform: uppercase;
                                letter-spacing: rem-calc(2);

                                span {
                                    font-size:      rem-calc(15);
                                    font-family:    'FreightTextBookItalic';
                                    margin-left:    rem-calc(5);
                                    text-transform: lowercase;
                                }
                            }
                        }
                    }
                }
            }

            .details__text__block {
                padding:  rem-calc(1) 0px rem-calc(50) 0px;
                position: relative;

                @include breakpoint(medium only) {
                    border-bottom: 1px solid $yellow;
                    height:        100%;
                }

                .inner__text {
                    @include breakpoint(medium only) {
                        position: relative;
                        top:      rem-calc(40);
                    }

                    .top__text {
                        // font-family:    'Gotham-Bold';
                        text-transform: uppercase;
                        text-align:     center;
                        font-size:      rem-calc(10);
                        letter-spacing: rem-calc(5);
                        color:          $yellow;
                        margin-top:     rem-calc(40);
                        margin-bottom:  0px;
                    }

                    .slashes {
                        text-align:    center;
                        margin-bottom: rem-calc(15);

                        span {
                            font-size:      rem-calc(12);
                            letter-spacing: rem-calc(4);
                            color:          $yellow;
                        }
                    }

                    .text__paragraph {
                        font-family: 'FreightTextBookItalic';
                        font-size:   rem-calc(26);
                        text-align:  center;
                        color:       #bbbbbb;
                        padding:     0px rem-calc(20);
                        position:    relative;
                        z-index:     3;

                        @include breakpoint(medium only) {
                            max-width:      rem-calc(550);
                            margin:         auto;
                            letter-spacing: rem-calc(2);
                            word-spacing:   rem-calc(2);
                            font-size:      rem-calc(22);
                            padding:        0px rem-calc(50);
                        }

                        @include breakpoint(large up) {
                            margin:    auto;
                            max-width: rem-calc(500);
                        }
                    }

                    .dash {
                        width:      rem-calc(50);
                        height:     rem-calc(1);
                        margin:     auto;
                        background: $yellow;
                        margin-top: rem-calc(30);
                    }
                }

                .arrow__wrapper {
                    .arrow {
                        z-index: 2;
                        bottom:  calc(50% - 25px);

                        @include breakpoint(medium only) {
                            right: rem-calc(-20);
                        }

                        @include breakpoint(large up) {
                            left: rem-calc(-20);
                        }
                    }
                }
            }
        }

        .slick-dots {
            bottom: rem-calc(10);

            button {
                width:         rem-calc(10);
                height:        rem-calc(10);
                border:        1px solid $white;
                border-radius: rem-calc(100);
                padding:       0px;

                &::before {
                    color: transparent;
                }
            }

            .slick-active {
                button {
                    background: $yellow;
                }
            }
        }

    }

    .upper__footer {

        @include breakpoint(large up) {
            padding-bottom: 0px;
        }
    }

    .footer {
        background-color: #e5e5e5;

        @include breakpoint(medium down) {
            background-color: $dark-blue;
        }
    }
}

.case__study {
    .related__projects {
        margin:         0;
        margin-top:     rem-calc(50);
        padding-bottom: rem-calc(20);

        @include breakpoint(large up) {
            //margin-right: rem-calc(20);
            margin-right: rem-calc(-5);

            .row {
                @include flex-grid-row('collapse', 'expand');

                .columns {
                    &:first-child {
                        padding-left: rem-calc(15);
                    }
                }
            }
        }
    }

    .divider {
        width:        auto;
        height:       rem-calc(1);
        margin:       0 rem-calc(20);
        margin-left:  20px;
        margin-right: 20px;
        background:   $gray;

        @include breakpoint(medium down) {
            margin: 0px rem-calc(20);
        }
    }
}

.size {
    position: relative;
    z-index: 1;
}
