/* Template
    <div class="side__labels show-for-large">
        <div class="side__label label-1"></div>
        <div class="side__label label-2"></div>
    </div>
*/

.side__labels 
{
    display: none;

    @include breakpoint(large up)
    {
        display: flex;
        height: 100%;
        align-items: center;
        position: absolute;
        top: 0;

        &.left
        {
            left: rem-calc(35);

            .side__label
            {
                transform: rotate(180deg);
                -webkit-transform: rotate(180deg);
                top: rem-calc(-12);
            }
        }

        &.right
        {
            right: rem-calc(35);
        }

        .side__label
        {
            color: $ash-gray;
            font-family: 'ButlerStencil-ExtraBold';
            font-size: rem-calc(14);
            letter-spacing: rem-calc(12);
            text-transform: uppercase;
            text-orientation: mixed;
            writing-mode: vertical-lr;
            position: relative;
            top: rem-calc(12);
        }
    }
}





/* Old styling
.dynamic__side__labels
{
    display: none;
    height:  0px;

    @include breakpoint(large up) 
    {
        display: block;

        .side__label {
            text-transform: uppercase;
            font-family:    'ButlerStencil-ExtraBold';
            font-size:      rem-calc(14);
            letter-spacing: rem-calc(12);
            color:          $ash-gray;
            padding-left:   rem-calc(12);
            position:       absolute;
            top:            calc(50% - 10px);
            cursor:         vertical-text;

            &.label-1 {
                // top: calc(50% - 30px);
                left:      rem-calc(-55);
                transform: rotate(-90deg);
            }

            &.label-2 {
                right:     rem-calc(-55);
                transform: rotate(90deg);
                
                
                transform-origin: 50% 100%;
        
                right: 0;
                padding: 1em;
                transform: rotate(90deg) translate(150%, 0%);
                transform-origin: 100% 0;
                position: absolute;
                // background: #FF1493;
            }
        }
    }
}
*/