.content__block
{
	position: relative;

	$height-mobile: 250;
	$height-mobile: 320;

	@include breakpoint(medium up)
	{
		height: rem-calc(320);
		margin: 0px rem-calc(20);
	}

	@include breakpoint(large up)
	{
		height: rem-calc(500);
	}

	.inner__block
	{
		position: relative;

		@include breakpoint(medium up)
		{
			display: inline-block;
		}

		&.content__image
		{

			height: rem-calc($height-mobile);

			//background: #eee;
			background-size: cover;
			background-position: 50%;
			background-repeat: no-repeat;

			@include breakpoint(medium up)
			{
				width: rem-calc(320);
				height: rem-calc(320);
			}

			@include breakpoint(large up)
			{
				width: 55%;
				height: rem-calc(500);
			}

			.inner__text
			{
				display: none;
				text-transform: uppercase;
				text-align: center;
				font-family: 'Gotham-Bold';
				font-size: rem-calc(60);
				letter-spacing: rem-calc(4);
				color: $hot-pink;
				
				position: relative;
				//position text 50% from top of parent minus half its height
				top: calc(50% - 45px);
				padding-left: rem-calc(4);
			}
		}

		&.left
		{
			@include breakpoint(medium up)
			{
				max-width: rem-calc(380);
				position: absolute;
				top: 0px;
				left: 0px;

				&.content__image
				{
					max-width: none;
					position: absolute;
				}
			}

			@include breakpoint(large up)
			{
				max-width: 40%;

				.text__block
				{
					margin-top: rem-calc(50);
				}
			}
		}

		&.right
		{
			@include breakpoint(medium up)
			{
				max-width: rem-calc(380);
				position: absolute;
				top: 0px;
				right: 0px;

				&.content__image
				{
					max-width: none;
					position: absolute;
				}

				.text__block
				{
					&.underlayed
					{
						margin-top: rem-calc(40);
					}
				}
			}

			@include breakpoint(large up)
			{
				max-width: 40%;

				.text__block
				{
					&.underlayed 
					{
						margin-top: rem-calc(70);
					}
				}
			}
		}
	}
}