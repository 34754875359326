.process 
{
    .page__label::after
    {
        content: "Our Process";
    }

    .image__block
    {
        $height-mobile: 250;
        $height-mobile: 320;

        height: rem-calc($height-mobile);

        //background: #eee;
        background-size: cover;
        background-position: 50%;
        background-repeat: no-repeat;

        @include breakpoint(medium up)
        {
            height: rem-calc(320);
        }

        @include breakpoint(large up)
        {
            height: rem-calc(500);
            margin-left: rem-calc(50);
        }

        .inner__text
        {
            display: none;
            text-transform: uppercase;
            text-align: center;
            font-family: 'Gotham-Bold';
            font-size: rem-calc(60);
            letter-spacing: rem-calc(4);
            color: $hot-pink;
            
            position: relative;
            //position text 50% from top of parent minus half its height
            top: calc(50% - 45px);
            padding-left: rem-calc(4);
        }
    }

    .right
    {
        .clean
        {
            padding-left: rem-calc(20);
            padding-right: rem-calc(20);
        }
    }

    .text__block 
    {
        position: relative;
        z-index: 1;
        margin: auto;

        @include breakpoint(large up)
        {
            padding-top: 100px;
            max-width: rem-calc(600) !important;

            .text 
            {
                &.clean
                {
                    font-size: rem-calc(14);
                    line-height: rem-calc(42);
                }

                &.fancy
                {
                    font-size: rem-calc(30);
                    line-height: rem-calc(52);
                }
            }
        }
    }

    #discover
    {
        @include breakpoint(large up)
        {
            //margin-top: rem-calc(130);
            margin-top: rem-calc(142);
        }
    }

    #numbered-3
    {
        @include breakpoint(medium down)
        {
            margin-bottom: rem-calc(40);
        }
    }

    @include breakpoint(large up)
    {
        #numbered-1
        {
            position: relative;
            bottom: rem-calc(50);
        }

        #design
        {
            position: relative;
            bottom: rem-calc(100);
        }

        #numbered-2
        {
            position: relative;
            bottom: rem-calc(150);
            z-index: -1;
        }

        #build
        {
            position: relative;
            bottom: rem-calc(250);
        }

        #numbered-3
        {
            position: relative;
            bottom: rem-calc(200);
            margin-bottom: rem-calc(-100);
        }
    }

    @include breakpoint(small only) {
        #numbered-1 {
            .text__block {
                padding-bottom: rem-calc(70);
            }
        }

        #numbered-2 {
            .text__block {
                padding-bottom: rem-calc(120);

            }
        }
    }
}

