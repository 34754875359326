.errors {
    #error__container {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        text-align: center;
        min-height: 100vh;

        .error__number {
            font-size: rem-calc(16);
            color:          $gray;
            letter-spacing: rem-calc(1);
        }

        .dash {
            width: rem-calc(50);
            height: rem-calc(1);
            background: $orange;
            margin-bottom: rem-calc(40);
            margin-left: auto;
            margin-right: auto;
        }

        .text {
            @include breakpoint(small only)
            {
                font-size: rem-calc(20);
                line-height: rem-calc(32);
            }
        }

        .text__block
        {
            &.centered
            {
                @include breakpoint(large up)
                {
                    max-width: rem-calc(900);
                }
            }
        }

        .back__link {
            font-family: 'FreightTextBookItalic';
            font-size: rem-calc(14);
            line-height: rem-calc(24);
            letter-spacing: rem-calc(1);
            word-spacing: rem-calc(1);
            color: $blue;
            text-decoration: underline;
        }

    }
}
