section#project {
    .makeshift__apostrophe {
        display:   inline-block;
        transform: rotate(180deg);
        position:  relative;
        bottom:    rem-calc(20);
    }

    .arrow__wrapper {
        height: 0px;

        .arrow {
            background: $white;
            width:      rem-calc(50);
            height:     rem-calc(50);
            transform:  rotate(45deg);
            position:   absolute;
        }
    }

    #project__banner {
        position: relative;
        overflow: hidden;

        &.fixed {
            @include breakpoint(large) {
                width:    100%;
                height:   rem-calc(500);
                position: fixed;
                top:      rem-calc(142);
            }
        }

        .background__wrapper {
            .background {
                width:               100%;
                height:              rem-calc(500);
                background-size:     cover;
                background-position: 50%;
                background-repeat:   no-repeat;
            }
        }
    }

    .headings__wrapper {
        height:   0px;
        //position: absolute;
        //bottom:   rem-calc(48);
        // position: fixed;
        position: relative;
        top:      -112px;

        /*
        @include breakpoint(large up) {
            bottom: rem-calc(110);
        }
        */

        @include breakpoint(small only) {
            top:     rem-calc(-37);
        }


        @include breakpoint(medium only) {
            // position: fixed;
            // top: rem-calc(460);
            top:     rem-calc(-90);
            z-index: 0;
        }

        @include breakpoint(large up) {
            // position: fixed;
            // top: rem-calc(532);
            z-index: 0;
        }

        .heading {
            text-transform: uppercase;
            font-family:    'ButlerStencil-ExtraBold';
            font-size:      rem-calc(54);
            color:          $white;
            margin:         0px;
            padding-left:   rem-calc(5);

            @include breakpoint(small only) {
                font-size:   rem-calc(40);
            }

            @include breakpoint(medium up) {
                font-size: rem-calc(100);
            }

            @include breakpoint(large up) {
                font-size:      rem-calc(120);
                letter-spacing: rem-calc(20);
            }
        }

        .sub__heading {
            display: none;

            @include breakpoint(large up) {
                display:        block;
                text-transform: uppercase;
                font-family:    'Gotham-Medium';
                font-size:      rem-calc(12);
                letter-spacing: rem-calc(6);
                color:          $white;
                position:       absolute;
                //bottom:         rem-calc(-12);
                bottom:         rem-calc(-5);
                //left:           rem-calc(50);
                left:           rem-calc(100);
            }
        }
    }

    .page__content {
        position: relative;
        z-index:  1;

        @include breakpoint(large) {
            &.fixed {
                margin-top: rem-calc(642);
                background: $white;
            }
        }
    }

    #project__overview {
        background: #fff;
        //margin-bottom: rem-calc(50);
        // margin-top:    rem-calc(40);

        @include breakpoint(medium only) {
            // margin-bottom: rem-calc(60);
        }

        @include breakpoint(large up) {
            // margin-bottom: rem-calc(80);
           // margin-top: 0px;
        }
        
        &.fixed {
            // margin-top: 40.125rem;
        }

        .arrow__wrapper {
            .arrow {
                top:  rem-calc(100);
                left: rem-calc(-30);
            }
        }

        .year__label__wrapper {
            height: 0px;

            .year__label {
                text-align:  right;
                font-family: 'ButlerStencil-ExtraBold';
                font-size:   rem-calc(90);
                color:       $white;
                position:    absolute;
                bottom:      rem-calc(-25);
                right:       rem-calc(50);
                height: rem-calc(115);
            }
        }
    }

    .project__slideshow {
        margin-bottom: 0px !important;

        .slide__image {
            height:              rem-calc(400);
            background-size:     contain;
            background-color:    #fff;
            background-position: 50%;
            background-repeat:   no-repeat;

            @include breakpoint(large up) {
                height: rem-calc(600);
            }
        }

        .slick-dots {
            bottom: rem-calc(10);

            button {
                width:         rem-calc(10);
                height:        rem-calc(10);
                border:        1px solid $yellow;
                border-radius: rem-calc(100);
                padding:       0px;

                &:before {
                    color: transparent;
                }
            }

            .slick-active {
                button {
                    background: $yellow;

                    &:before {
                        color: transparent;
                    }
                }
            }
        }
    }

    .split__section {
        .feature__item {
            height:     rem-calc(375);
            background: $white;
            position:   relative;

            @include breakpoint(1600px) {
                height: rem-calc(500);
            }

            &.text__item {
                .inner__text {
                    display:  inline-block;
                    width:    100%;
                    position: relative;
                    top:      rem-calc(100);

                    .heading {
                        text-transform: uppercase;
                        text-align:     center;
                        font-family:    'ButlerStencil-ExtraBold';
                        font-size:      rem-calc(16);
                        letter-spacing: rem-calc(6);
                        margin-bottom:  rem-calc(20);
                        color:          #aaaaaa;
                    }

                    .details__description {
                        font-family: 'FreightTextBookItalic';
                        font-size:   rem-calc(26);
                        text-align:  center;
                        color:       $blue;
                        padding:     0px rem-calc(20);
                        max-width:   rem-calc(450);
                        margin:      auto;

                        @include breakpoint(large down) {
                            font-size:   rem-calc(19);
                        }

                    }

                    .dash {
                        width:      rem-calc(50);
                        height:     1px;
                        margin:     auto;
                        background: $yellow;
                    }

                    .slashes {
                        text-align:    center;
                        margin-bottom: rem-calc(15);

                        span {
                            font-size:      rem-calc(12);
                            letter-spacing: rem-calc(4);
                            color:          $yellow;
                        }
                    }

                    .top__text {
                        text-transform: uppercase;
                        text-align:     center;
                        font-family:    'Gotham-Medium';
                        font-size:      rem-calc(10);
                        letter-spacing: rem-calc(5);
                        color:          $yellow;
                        margin-top:     rem-calc(40);

                        &.top__text {
                            margin-bottom: 0px;
                        }
                    }
                }

                .arrow__wrapper {
                    .arrow {
                        @include breakpoint(small only) {
                            bottom: rem-calc(-20);
                            left:   calc(50% - 25px);
                        }

                        @include breakpoint(medium up) {
                            margin-top: -35px;
                            left:       rem-calc(-20);
                            bottom:     auto;
                            top:        50%;
                        }
                    }
                }
            }

            &.details {
                .inner__text {
                    top: rem-calc(25);

                    @include breakpoint(1600px) {
                        top: rem-calc(100);
                    }

                    .dash {
                        margin-bottom: rem-calc(25);
                    }

                    .details__description {
                        color: #bbbbbb;
                    }
                }
            }

            &.image__item {
                background-size:     cover;
                background-position: 50%;
                background-repeat:   no-repeat;
            }

            &.location__item {
                background: #eeeeee;

                .location__details {
                    overflow: hidden;
                    position: relative;
                    top:      rem-calc(40);
                    left:     rem-calc(60);

                    .size {
                        .text {
                            font-family:    'Gotham-Medium';
                            font-size:      rem-calc(10);
                            text-transform: uppercase;
                            letter-spacing: rem-calc(2);

                            span {
                                font-size:   rem-calc(18);
                                font-family: 'FreightTextBookItalic';
                                margin-left: rem-calc(5);

                                sup {
                                    text-transform: lowercase;
                                }
                            }
                        }
                    }
                }
            }
        }
    }

    .related__projects {
        margin:         0;
        margin-top:     rem-calc(50);
        padding-bottom: rem-calc(20);

        @include breakpoint(large up) {
            //margin-right: rem-calc(20);
            margin-right: rem-calc(-5);

            .row {
                @include flex-grid-row('collapse', 'expand');

                .columns {
                    &:first-child {
                        padding-left: rem-calc(15);
                    }
                }
            }
        }
    }

    .divider {
        width:        auto;
        height:       rem-calc(1);
        margin:       0 rem-calc(20);
        margin-left:  20px;
        margin-right: 20px;
        background:   $gray;

        @include breakpoint(medium down) {
            margin: 0px rem-calc(20);
        }
    }

    .upper__footer {

        @include breakpoint(large up) {
            padding-bottom: 0px;
        }
    }

    .footer {
        background-color: #e5e5e5;

        @include breakpoint(medium down) {
            background-color: $dark-blue;
        }
    }
}