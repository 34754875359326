.menu__bar
{   
    @include breakpoint(medium only)
    {
        padding: rem-calc(20) 0px;
    }

    @include breakpoint(large up)
    {
        padding: rem-calc(40) 0px;
        padding-bottom: rem-calc(52);
        padding-left: rem-calc(30);
    }

    &.fixed
    {
        @include breakpoint(large up)
        {
            position: fixed; 
            background: rgba(255, 255, 255, 0.8);
            width: 100%;
            //z-index: 10;
            z-index: 20;

            top: 0px;
            padding-right: rem-calc(36);
        }
    }

    .menu__bar__left
    {
        display: none;

        @include breakpoint(large up)
        {
            display: inline-block;
            width: rem-calc(500);
        }

        @include breakpoint(1024px)
        {
            width: auto;
        }

        @include breakpoint(1500px)
        {
            width: auto;
        }

        .menu__bar__logo
        {
            position: relative;

            .logo__wrapper
            {
                display: inline-block;

                img 
                {
                    width: auto;
                    height: rem-calc(30);

                    @include breakpoint(1400px)
                    {
                        height: rem-calc(50);
                    }
                }
            }   

            .slash__wrapper
            {
                position: static;

                .slash 
                {
                    height: rem-calc(80);
                    bottom: rem-calc(45);
                    left: rem-calc(30);
                    margin-right: rem-calc(60);

                    @include breakpoint(1400px)
                    {
                        height: rem-calc(100);
                        bottom: rem-calc(50);
                    }
                }
            }

            .logo__text
            {
                display: inline-block;
                font-family: 'Gotham-Medium';
                font-size: rem-calc(10);
                text-transform: uppercase;
                letter-spacing: rem-calc(6);
                //general darker grey
                color: $ash-gray;
                position: relative;
                top: rem-calc(5);
                //left: rem-calc(35);
                //float: right;

                @include breakpoint(large up)
                {
                    letter-spacing: rem-calc(3);
                    top: rem-calc(0);

                }

                @include breakpoint(1400px)
                {
                    top: rem-calc(5);
                    letter-spacing: rem-calc(3);
                    font-size: rem-calc(14);
                }

                @include breakpoint(1450px)
                {
                    letter-spacing: rem-calc(6);
                }

            }
        }
    }

    .menu__bar__right
    {
        display: block;

        @include breakpoint(large up)
        {
            display: inline-block;
            float: right;
        }

        .menu__bar__links
        {
            @include breakpoint(medium down)
            {
                padding-top: rem-calc(30);
            }

            @include breakpoint(large up)
            {
                position: relative;
                top: rem-calc(16);
            }

            .links__container
            {
                text-align: center;

                @include breakpoint(large up)
                {
                    text-align: right;
                    display: inline-block;
                    position: relative;
                    bottom: rem-calc(18);
                    margin-right: rem-calc(20);
                }

                @include breakpoint(1400px)
                {
                    bottom: rem-calc(8);
                    margin-right: rem-calc(50);
                }

                .link__item
                {
                    text-align: center;
                    display: inline-block;

                    .link 
                    {
                        font-family: 'ButlerStencil-ExtraBold';
                        text-transform: uppercase;
                        color: $dark-blue;
                        font-size: rem-calc(9);
                        letter-spacing: rem-calc(3);
                        border: 1px solid transparent;
                        padding-bottom: rem-calc(7);
    
                        position: relative;
                        text-decoration: none;

                        @include breakpoint(medium up)
                        {
                            font-size: rem-calc(14);
                            letter-spacing: rem-calc(6);
                        }

                        @include breakpoint(large up)
                        {
                            font-size: rem-calc(10);
                        }

                        @include breakpoint(large up)
                        {
                            font-size: rem-calc(10);
                        }

                        @include breakpoint(1400px)
                        {
                            font-size: rem-calc(14);
                        }
                        
                        &.active
                        // &:hover
                        {
                            border-bottom-color: $yellow;
                        }
                        
                        &:not(.active) {
                            &:before {
                                content:          "";
                                position:         absolute;
                                width:            0%;
                                height:           1px;
                                bottom:           -1px;
                                left:             0;
                                background-color: $yellow;
                                transition:       all 0.4s linear;
                            }
    
                            &:hover:before {
                                width: 100%;
                            }
                        }
                    }

                    &.middle__link
                    {
                        margin: 0px rem-calc(20);

                        @include breakpoint(medium up)
                        {
                            margin: 0px rem-calc(80);
                        }

                        @include breakpoint(1024px)
                        {
                            margin: 0px rem-calc(20);
                        }

                        @include breakpoint(1400px)
                        {
                            margin: 0px rem-calc(40);
                        }

                        @include breakpoint(1450px)
                        {
                            margin: 0px rem-calc(80);
                        }
                    }
                }
            }

            .filter__button__wrapper
            {
                text-align: center;

                @include breakpoint(medium down)
                {
                    margin: rem-calc(25) 0px;
                }

                @include breakpoint(medium only)
                {
                    margin-top: rem-calc(40);
                }

                @include breakpoint(large up)
                {
                    display: inline-block;
                    position: relative;
                    bottom: rem-calc(18);
                    margin-right: rem-calc(20);
                }

                @include breakpoint(1400px)
                {
                    bottom: rem-calc(8);
                    margin-right: rem-calc(50);
                }

                .filter__button 
                {
                    color: $yellow;
                    cursor: pointer;
                    background-color: $dark-blue;
                    //border: 1px solid $yellow;
                    text-transform: uppercase;
                    font-family: 'Gotham-Medium';
                    letter-spacing: rem-calc(6);
                    font-size: rem-calc(10);
                    padding: rem-calc(12) rem-calc(12) rem-calc(12) rem-calc(18);
                    text-align: center;
    
                    -webkit-transition: all 0.5s; /* Safari */
                    transition: all 0.5item__overs;

                    @include breakpoint(small only)
                    {
                        display: none;
                    }
                    @include breakpoint(large up)
                    {
                        font-size: rem-calc(10);
                        //padding-bottom: rem-calc(1);
                    }

                    @include breakpoint(1400px)
                    {
                        font-size: rem-calc(14);
                        padding-bottom: rem-calc(12);
                    }
                    
                    &:hover {
                        background: $yellow;
                        color: #fff;
                    }
                }
            }
        }
    }

    &.triple__item__menu
    {
        @include breakpoint(small only)
        {
            padding: rem-calc(1) 0px;
        }

        @include breakpoint(medium only)
        {
            padding: rem-calc(20) 0px rem-calc(50) 0px;
        }

        .menu__bar__right
        {
            .menu__bar__links
            {
                .links__container
                {
                    @include breakpoint(large)
                    {
                        bottom: rem-calc(10);
                        margin-right: rem-calc(100);
                    }

                    @include breakpoint(1400px)
                    {
                        top: 0px;
                        margin-right: rem-calc(200);
                    }

                    .middle__link
                    {
                        margin: 0px rem-calc(50);

                        @include breakpoint(large)
                        {
                            margin: 0px rem-calc(100);
                        }

                        @include breakpoint(1400px)
                        {
                            margin: 0px rem-calc(200);
                        }
                    }
                }
            }
        }
    }
}







