.overlay__menu {
    position:         fixed;
    z-index:          100;
    width:            100%;
    //height:           100vh;
    height:           100%;
    background-color: rgba(255, 255, 255, 0.95);
    padding:          rem-calc(15) rem-calc(10);

    top:              0;

    .overlay__inner {
        height:  100%;
        padding: rem-calc(30) rem-calc(34) rem-calc(20) rem-calc(38);
        //border:  1px solid $yellow;

        .frame__segment
        {
            background-color: $yellow;
            position: absolute;
            $frame-margin-vertical: 15;
            $frame-margin-horizontal: 10;
            z-index: 1;
            $transition-delay: 0.5s;

            &.frame__top
            {
                //total width minus double the horizontal side margin
                //width: calc(100% - 20px);
                width: 0px;
                height: rem-calc(1);
                top: rem-calc($frame-margin-vertical);
                left: rem-calc($frame-margin-horizontal);
                //right: rem-calc($frame-margin-horizontal);
                transition: width 0.5s linear;
            }

            &.frame__right
            {
                width: rem-calc(1);
                //total height minus double the vertical side margin
                //height: calc(100% - 30px);
                height: 0px;
                right: rem-calc($frame-margin-horizontal);
                top: rem-calc($frame-margin-vertical);
                bottom: rem-calc($frame-margin-vertical);
                transition: height 0.5s linear;
                transition-delay: $transition-delay;
            }        

            &.frame__bottom
            {
                //width: calc(100% - 20px);
                width: 0px;
                height: rem-calc(1);
                bottom: rem-calc($frame-margin-vertical);
                //left: rem-calc($frame-margin-horizontal);
                right: rem-calc($frame-margin-horizontal);
                transition: width 0.5s linear;
                transition-delay: $transition-delay * 2;
            }

            &.frame__left
            {
                width: rem-calc(1);
                //height: calc(100% - 30px);
                height: 0px;
                left: rem-calc($frame-margin-horizontal);
                bottom: rem-calc($frame-margin-vertical);
                //top: rem-calc($frame-margin-vertical);
                top: initial;
                transition: height 0.5s linear;
                transition-delay: $transition-delay * 3;
            }
        }

        .overlay__content {
            height: 100%;

            @include breakpoint(large up) {
                padding-top: rem-calc(40);
            }

            .overlay__header {
                margin-bottom: rem-calc(30);

                @include breakpoint(medium only) {
                    margin-bottom: rem-calc(40);
                }

                @include breakpoint(large up) {
                    margin-bottom: rem-calc(80);
                }

                .overlay__title {
                    display:        inline-block;
                    text-transform: uppercase;
                    font-family:    'ButlerStencil-ExtraBold';
                    letter-spacing: rem-calc(8);
                    margin:         0px;
                    margin-right:   rem-calc(15);
                    color:          $yellow;

                    @include breakpoint(large up) {
                        margin-right: rem-calc(50);
                        font-size:    rem-calc(40);
                    }
                }

                .overlay__description {
                    font-family: 'FreightTextBookItalic';
                    font-size:   rem-calc(14);
                    color:       $blue;
                    margin-top:  rem-calc(14);

                    @include breakpoint(medium only) {
                        font-size: rem-calc(18);
                    }

                    @include breakpoint(large up) {
                        display:     inline-block;
                        margin:      0px;
                        font-size:   rem-calc(26);
                        position:    relative;
                        bottom:      rem-calc(5);
                        margin-left: rem-calc(30);
                    }
                }
            }

            .overlay__next__button {
                position: absolute;
                right: rem-calc(20);
                bottom: rem-calc(26);
                text-transform: uppercase;
                color: $yellow;
                letter-spacing: rem-calc(6);
                font-size: rem-calc(24);
                font-family: 'ButlerStencil-ExtraBold';
                border: 1px solid $yellow;
                padding: rem-calc(18) rem-calc(22) rem-calc(12) rem-calc(28);

                @include breakpoint(medium up) {
                    right:  rem-calc(50);
                    bottom: rem-calc(50);
                }

                &:hover {
                    background-color: $yellow;
                    color:            $white;
                }
            }


            .overlay__submit__button {
                position:       absolute;
                right:          rem-calc(20);
                bottom:         rem-calc(26);
                text-transform: uppercase;
                color:          $yellow;
                background-color: $dark-blue;

                letter-spacing: rem-calc(6);
                font-size:      rem-calc(24);
                //font-family: 'Gotham-Medium';
                font-family:    'ButlerStencil-ExtraBold';
                //border:         1px solid $yellow;
                padding: rem-calc(18) rem-calc(22) rem-calc(12) rem-calc(28);

                /* old
                position: absolute;
                right: rem-calc(20);
                bottom: rem-calc(26);
                text-transform: uppercase;
                color: $yellow;
                letter-spacing: rem-calc(6);
                font-size: rem-calc(24);
                font-family: 'ButlerStencil-ExtraBold';
                border: 1px solid $yellow;
                padding: rem-calc(18) rem-calc(22) rem-calc(12) rem-calc(28);
                */

                @include breakpoint(medium up) {
                    right:  rem-calc(50);
                    bottom: rem-calc(50);
                }

                &:hover {
                    background-color: $yellow;
                    color:            $white;
                }
            }

            .typed-cursor {
                display: none;
            }

            textarea,
            input[type="text"] {
                transition:       none;
                box-shadow:       none;
                background-color: transparent;
                border:           0px;
                font-family:      'Gotham-Medium';
                font-size:        rem-calc(26);
                color:            $ash-gray;
                caret-color:      $yellow;
                padding-left:     0px;

                @include breakpoint(medium up) {
                    font-size: rem-calc(50);
                }

                @include breakpoint(large up) {
                    font-size: rem-calc(44);
                }

                &.typed,
                &.typed2,
                &.typed__name,
                &.typed2__name,
                &.typed__email,
                &.typed2__email {
                    @include breakpoint(medium up) {
                        height: rem-calc(70);
                    }
                }

                &.contact__input {
                    height:   100%;
                    position: relative;
                    //padding-top: rem-calc(60);
                    //bottom: rem-calc(65);
                    resize:   none;

                    @include breakpoint(medium up) {
                        margin-bottom: rem-calc(80);
                    }

                    &:focus {
                        transition:       none;
                        box-shadow:       none;
                        background-color: transparent;
                        border:           0px
                    }

                }

                &::selection {
                    color:            $dark-blue;
                    background-color: $yellow;
                }
            }
        }

    }
}