.off-canvas-wrapper
{
	overflow-y: scroll;

	.off-canvas 
	{
		.main-navigation
		{
			overflow-y: scroll;
			overflow-x: hidden;
			height: 100%;
		}
	}
	
	.off-canvas-content
	{
		@include breakpoint(medium down)
		{
			margin-top: rem-calc(50);
		}
		
		@include breakpoint(large up)
		{
			padding-right: rem-calc(36);
		}
	}
}