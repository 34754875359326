.off-canvas.position-right {
    background:          linear-gradient(rgba($blue, 0.85), rgba($blue, 0.85)), url('/img/off-canvas.jpg');
    background-position: right 65px top 0px;
    .main-navigation {
        /* weird scroll bar bug fix - Lee */
        //padding-bottom: 10px;
        //overflow-y: visible !important;

        height: 100%;
        padding: 0 !important;
    }
}
.main-navigation {
    .title-bar {
        float: left;
        display: block;
        position: static;
    }
    .main-nav-inner {
        float: left;
        display: block;
        font-size:           rem-calc(14);
        font-family:         'Gotham-Medium';
        box-shadow:          none;
        //letter-spacing: rem-calc(5);
        text-transform:      uppercase;
        //background-color: $blue;
        //background-image: url();
        padding-left:        rem-calc(90);
        height:              100%;
        width: calc(100% - 2.25rem);

        .bottom-menu-items {
            position:       absolute;
            bottom:         rem-calc(25);
            right:          rem-calc(90);

            font-size:      rem-calc(16.04);
            font-family:    'FreightTextMediumItalic';
            color:          $orange;
            text-transform: none;
            letter-spacing: normal;

            @include breakpoint(medium up) {
                font-size: rem-calc(22);
            }

            span {
                cursor: pointer;
            }

            .careers {
                a {
                    font-family:    'FreightTextMediumItalic';
                    color:          $orange;
                    :hover {
                        color:          $orange;
                    }
                }
            }

            .search:before , .slideshow:before{
            //.search:before{
                content:       "";
                border-bottom: 1px solid $orange;
                width:         48px;
                display:       inline-block;
                margin-bottom: 0.25rem;
                margin-right:  rem-calc(5);
                cursor:        auto;

                @include breakpoint(medium up) {
                    width: rem-calc(80);
                }
            }
        }
    }

    .icon {
        position: absolute;
        bottom:   rem-calc(20);
        right:    rem-calc(20);
    }

    .menu-bar {
        width: 100%;

        @include breakpoint(medium down) {
            padding: rem-calc(10) 0px;
        }

        li {
            //margin-top: rem-calc(60);
            margin-top: rem-calc(30);
            padding:    0px;
            color:      $gray;
            position:   relative;
            //border: 2px solid transparent;
            //border-left: none;

            &::before,
            &::after {
                content:          '';
                width:            rem-calc(2);
                //height: rem-calc(20);
                height:           0px;
                background:       $orange;
                display:          block;
                position:         absolute;
                left:             rem-calc(-2);
                transition:       height 0.2s;
                transition-delay: 0.7s;

                @include breakpoint(medium up) {
                    //height: rem-calc(24);
                }
            }

            &::before {
                //bottom: rem-calc(-2)
                bottom: rem-calc(46);

                @include breakpoint(medium up) {
                    bottom: rem-calc(64);
                }
            }

            &::after {
                //top: rem-calc(-2);
                top: rem-calc(46);

                @include breakpoint(medium up) {
                    top: rem-calc(64);
                }
            }

            .grow__bar {
                position:         absolute;
                width:            0px;
                height:           rem-calc(2);
                background:       $yellow;
                top:              rem-calc(-2);
                transition:       width 0.5s;
                transition-delay: 0.2s;

                &.bottom {
                    top:    initial;
                    bottom: rem-calc(-2);
                }
            }

            @include breakpoint(small only) {
                margin-top: 1.15rem;

            }

            .plus__wrapper {
                width:    0px;
                position: relative;
                top:      rem-calc(25);
                right:    rem-calc(2);

                @include breakpoint(medium up) {
                    top: rem-calc(32);
                }

                .plus {
                }

                .plus:before, .plus:after {
                    content:            "";
                    position:           absolute;
                    background:         $light-gray;
                    top:                -10%;
                    width:              2px;
                    height:             rem-calc(16);
                    -webkit-transition: -webkit-transform 0.1s ease-in;

                    @include breakpoint(medium up) {
                        height: rem-calc(20);
                    }
                }

                .plus:before {
                    transform: rotate(90deg);
                }

                .plus:after {
                }
            }

            a {
                display:        inline-block;
                font-weight:    bold;
                font-size:      rem-calc(12);
                letter-spacing: rem-calc(8);
                color:          $gray;
                padding:        rem-calc(26) 0px rem-calc(26) rem-calc(35);
                position:       relative;
                top:            rem-calc(1);

                @include breakpoint(small) {
                    font-size: rem-calc(11);
                    letter-spacing: rem-calc(3);
                    top: rem-calc(3);
                }

                @include breakpoint(medium up) {
                    padding:        rem-calc(35) 0px;
                    padding-left:   rem-calc(55);
                    letter-spacing: rem-calc(12);
                    font-size:      rem-calc(16);
                }

                &:focus {
                    outline: none;
                }
            }

            .hover__icon__wrapper {
                display:  inline-block;
                float:    right;
                width:             rem-calc(50);
                height:            rem-calc(60);
                position: relative;
                left:     rem-calc(7);
                overflow: hidden;
                transform: matrix(1, 0, 0, 1, 0, 0);

                @include breakpoint(medium up) {
                    top: 12px;
                }
                @include breakpoint(small only)
                {
                    display: none;
                }

                .hover__icon {
                    opacity:           0;
                    width:             rem-calc(100);
                    height:            rem-calc(60);
                    background-image:  url('/img/dma_squiggle-01.svg');
                    background-repeat: repeat-x;
                    background-size:   rem-calc(38) rem-calc(60);
                    background-position: 0 0;
                    transition:        opacity 0.3s linear;
                    transition-delay:  0s;

                    //transform: matrix(1, 0, 0, 1, 0, 0);
                    position: absolute;
                    top: 0;
                    right: 0;
                }
            }
        }

        li:hover {
            //border-top: 2px solid $ash-gray;//$orange;
            //border-bottom: 2px solid $ash-gray;//$orange;

            @include breakpoint(medium up) {
                background-position-x: 102%;
            }

            &::before,
            &::after {
                transition-delay: 0s;
                height:           rem-calc(20);

                @include breakpoint(medium up) {
                    height: rem-calc(24);
                }
            }

            .grow__bar {
                width: 100%;
            }

            a {
                color: $orange;
            }

            .plus {
                &::before,
                &::after {
                    background: $orange;
                }

                &::before {

                    transform: rotate(135deg);
                }

                &::after {
                    transform: rotate(45deg);
                }
            }

            .hover__icon {
                opacity:          1;
                transition:       opacity 0.4s linear;
                transition-delay: 0.5s;
            }
        }

        .list-icon {

        }
    }

    .menu__heading {
        color:          $yellow;
        text-transform: uppercase;
        white-space:    rem-calc(3);
        float:          right;
        font-family:    'ButlerStencil-ExtraBold';
        font-size:      rem-calc(10);
        letter-spacing: rem-calc(5);
        padding-right:  rem-calc(31);
        padding-top:    rem-calc(31);

        &::before {
            content:       "";
            border-bottom: 1px solid $orange;
            width:         48px;
            display:       inline-block;
            margin-bottom: 0.125rem;
            margin-right:  1rem;
        }
    }

}

.title-bar {
    width:            rem-calc(36);
    height:           100%;
    position:         fixed;
    top:              0;
    right:            0;
    z-index:          99;
    margin:           0;
    padding:          0;
    background-color: $yellow;

    .title-bar-left {
        width:  100%;
        height: 100%;
        position: relative;

        .bar__upper {
            img {

            }
        }

        .bar__middle {
            width:    100%;
            position: absolute;
            // top offset is 50% total parent height minus height of element itself
            top:      calc(50% - 30px);

            .open-icon,
            .close-icon {
                display: block;
                width:   100%;
                height:  rem-calc(30);
                margin:  auto;

                &:hover {
                    cursor: pointer;

                    .line {
                        background-color: $blue;
                        border-color:     $blue;
                    }
                }

                .open-icon-inner,
                .close-icon-inner {
                    height: 100%;
                }

                .line {
                    width:            rem-calc(16);
                    height:           2px;
                    background-color: $white;
                    position:         relative;

                    transition:       all 0.5s ease;
                }
            }

            .open-icon {
                .open-icon-inner {
                    .line {
                        top:           rem-calc(7);
                        margin:        auto;
                        margin-bottom: rem-calc(5);
                    }

                    .line-1 {

                    }

                    .line-2 {

                    }

                    .line-3 {

                    }
                }
            }

            .close-icon {
                .close-icon-inner {
                    .line {
                        //top:     rem-calc(4);
                        display: inline-block;
                        width:            rem-calc(22);
                    }

                    .line-1 {
                        transform: rotate(45deg);
                        top: rem-calc(16);
                        //left:      rem-calc(10);
                    }

                    .line-2 {
                        transform: rotate(-45deg);
                        //right:     rem-calc(10);
                    }
                }
            }
        }

        .bar__lower {
            width:    100%;
            position: absolute;
            bottom:   0px;

            @include breakpoint(medium down) {
                padding-bottom: rem-calc(35);
            }

            .icon__wrapper {
                text-align:    center;
                margin-bottom: rem-calc(30);

                @include breakpoint(medium down) {
                    margin:     0px;
                    margin-top: rem-calc(80);
                }

                span {
                    color: darken($yellow, 15%);

                    @include breakpoint(medium down) {
                        font-size: rem-calc(24);
                    }
                }
            }
        }
    }

    /*
    .columns
    {
        padding-left: rem-calc(3);
        padding-right: rem-calc(3);

    }

    .row:nth-child(1) 
    {
        .columns
        {
            padding-top: rem-calc(0);
            padding-bottom: rem-calc(0);
            padding-left: rem-calc(0);
            padding-right: rem-calc(0);
        }
    }

     .row:nth-child(2) 
    {
         .columns
        {
            padding-top: rem-calc(224);
            padding-bottom: rem-calc(0);

            .menu-icon
            {
                margin-left: 0.45rem;
                margin-right: 0.25rem;
                width: 14.5px;
                height: 18px;
            }
        }
    }

     .row:nth-child(3) 
    {
        .columns
        {
            padding-top: rem-calc(160);
            padding-bottom: 40rem;
        }

        i
        {
            padding-bottom: 2rem;  
        }

        .fa
        {
            padding-left: rem-calc(8);
            color: $black;
            opacity: 0.2;
        } 
    }


    @include breakpoint(medium)
    {
  
    }

    @include breakpoint(large)
    {
        .row:nth-child(2) 
        {
            .columns
            {
                padding-top: rem-calc(360);
                padding-bottom: rem-calc(0);
            }
        }

        .row:nth-child(3) 
        {
            .columns
            {
                padding-top: rem-calc(296);
            }
        }
    }
    */
}

// styling for outer nav-bar for displays medium-down
@include breakpoint(medium down) {
    .off-canvas-content {
        .title-bar {
            width:   100%;
            height:  rem-calc(50);
            opacity: 0.9;

            .bar__upper,
            .bar__middle,
            .bar__lower {
                display: inline-block;
                width:   auto;
                height:  100%;
                top:     0px;
            }

            .bar__upper {
                float: left;

                img {
                    width:       auto;
                    height:      rem-calc(18);
                    margin-left: rem-calc(22);
                    position:    relative;
                    top:         rem-calc(12);
                }

                .slash__wrapper {
                    .slash {
                        background: $white;
                        height:     rem-calc(30);
                        bottom:     rem-calc(6);
                        left:       rem-calc(8);
                    }
                }

                .page__label {
                    position: relative;
                    left:     rem-calc(120);
                    bottom:   rem-calc(10);
                    height:   0px;

                    &::after {
                        display:        inline-block;
                        text-transform: uppercase;
                        font-family:    'Gotham-Medium';
                        font-size:      rem-calc(8);
                        letter-spacing: rem-calc(3);
                        color:          $white;
                        margin:         0px;

                        @include breakpoint(medium only) {
                            font-size: rem-calc(10);
                        }
                    }
                }
                .small__logo__text{
                     display:        inline-block;
                     width:          auto;
                     height:         1.125rem;
                     margin-left:    1.375rem;
                     position:       relative;
                     top:            0.75rem;
                     text-transform: uppercase;
                     font-family:    'Gotham-Medium';
                     font-size:      rem-calc(12);
                 }
            }

            .bar__middle {
                position:     relative;
                float:        right;
                margin-right: rem-calc(22);

                .open-icon {
                    height: 100%;

                    .open-icon-inner {
                        .line {
                            top: rem-calc(18);
                        }
                    }
                }
            }

            .bar__lower {

            }
        }
    }

    .inner-bar {
        .title-bar-left {
            .bar__middle {
                top: rem-calc(18);

                .close-icon {
                    .close-icon-inner {
                        .line {
                            display:  block;
                            position: absolute;
                            width:    rem-calc(20);
                            top:      rem-calc(7);
                        }

                        .line-1 {
                            left: rem-calc(8);
                        }

                        .line-2 {
                            left: rem-calc(8);
                        }

                        @include breakpoint(large up) {
                            position: relative;
                            top:      rem-calc(14);
                            left:     rem-calc(10);
                        }
                    }
                }
            }
        }
    }
}