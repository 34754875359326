.filter__overlay
{
    width: 100%;
    height: auto;
    overflow-y: scroll;
    padding: 0px;

    @include breakpoint(large up)
    {
        overflow: hidden;
    }
    
    .filter__overlay__inner
    {
        padding: 0px;
        border: none;

        .filter__overlay__content
        {
            width: 100%;
            height: 100vh;
            border: none;
            padding: 0px;
            padding-top: rem-calc(45);
            overflow-y: scroll;

            $base-left-margin: 50;
            $base-left-margin-double: $base-left-margin * 2;


            .filter__overlay__header
            {
                padding-left: rem-calc($base-left-margin);

                @include breakpoint(large up)
                {
                    margin-top: rem-calc(40);
                }

                .slash__wrapper
                {
                    //default styling in separate component file
                    .slash 
                    {
                        @include breakpoint(medium only)
                        {
                            height: rem-calc(80);
                            bottom: rem-calc(55);
                        }
                    }
                }  
            }

            .filter__items__container
            {
                margin-top: rem-calc(40);
                position: relative;

                @include breakpoint(small only)
                {
                    margin-bottom: rem-calc(150);
                }

                @include breakpoint(medium only)
                {
                    margin-top: rem-calc(60);
                }

                @include breakpoint(large up)
                {
                    margin-top: rem-calc(55);
                }

                .filter__item__wrapper 
                {
                    @include breakpoint(large up)
                    {
                        display: inline-block;
                        position: relative;
                        width: rem-calc(280);
                        margin-right: rem-calc(20);
                    }

                    @include breakpoint(1200px)
                    {
                        margin-right: rem-calc(60);   
                    }

                    @include breakpoint(1400px)
                    {
                        margin-right: rem-calc(150);   
                    }

                    .filter__item 
                    {
                        margin-bottom: rem-calc(50);

                        @include breakpoint(medium up)
                        {
                            float: left;
                        }

                        @include breakpoint(medium only)
                        {
                            float: none;
                            max-width: rem-calc(300);
                            margin-bottom: rem-calc(50);
                        }

                        @include breakpoint(large up)
                        {
                            display: inline-block;
                            position: absolute;
                            top: 0px;


                            &.filter__item-1
                            {
                                left: 0px;
                            }

                            &.filter__item-2
                            {
                                .list__item__inner 
                                {
                                    margin-left: rem-calc(50);
                                }
                            }

                            &.filter__item-3
                            {
                                .list__item__inner 
                                {
                                    margin-left: rem-calc(50);
                                }
                            }
                        }

                        //for filter item text heading and items
                        $box-width: 190;
                        $box-width-medium: 260;

                        .item__title
                        {
                            .number__label__wrapper
                            {
                                width: 0px;
                                height: 0px;
                                margin-left: rem-calc($base-left-margin);
                                margin-bottom: rem-calc(6);

                                .number__label
                                {
                                    font-family: 'ButlerStencil-ExtraBold';
                                    font-size: rem-calc(16);
                                    color: $yellow;
                                    letter-spacing: rem-calc(3);

                                    @include breakpoint(medium only)
                                    {
                                        font-size: rem-calc(24);
                                        position: relative;
                                        right: rem-calc(10);
                                    }
                                }
                            }

                            .text__label__wrapper
                            {
                                display: inline-block;
                                margin-left: rem-calc($base-left-margin + 42);

                                .text__label 
                                {
                                    display: inline-block;
                                    text-transform: uppercase;
                                    font-family: 'Gotham-Medium';
                                    color: $blue;
                                    margin: 0px;
                                    font-size: rem-calc(16);
                                    letter-spacing: rem-calc(6);
                                    border-bottom: 1px solid $yellow;
                                    padding-bottom: rem-calc(8);
                                    margin-bottom: rem-calc(20);
                                    text-align: left;
                                    width: rem-calc($box-width);

                                    @include breakpoint(medium only)
                                    {
                                        width: rem-calc($box-width-medium);
                                        font-size: rem-calc(22);
                                    }
                                }
                            }
                        }

                        .list__items__container
                        {
                            .list__item 
                            {
                                padding: rem-calc(10) 0px;

                                .list__item__inner
                                {
                                    margin-left: rem-calc($base-left-margin + 42);
                                    width: rem-calc($box-width);
                                    text-align: left;
                                    position: relative;

                                    input[type="checkbox"]
                                    {
                                        -webkit-appearance: none;
                                        width: rem-calc(15);
                                        height: rem-calc(15);
                                        position: absolute;
                                        top: rem-calc(6);
                                        left: rem-calc(-25);
                                        z-index: 10;

                                        &:focus
                                        {
                                            outline: none;
                                        }

                                        &:checked
                                        {
                                            ~.icon__wrapper
                                            {
                                                bottom: 0px; 
                                                right: rem-calc(28);

                                                .icon 
                                                {
                                                    transform: rotate(-10deg);
                                                    position: relative;
                                                    bottom: rem-calc(1);

                                                    .line 
                                                    {
                                                        background-color: $yellow;
                                                        
                                                        &.line-1
                                                        {
                                                            width: rem-calc(16);
                                                            transform: rotate(-45deg);
                                                            left: rem-calc(4);
                                                            bottom: rem-calc(2);
                                                        }

                                                        &.line-2
                                                        {
                                                            width: rem-calc(8);
                                                            transform: rotate(45deg);
                                                        }
                                                    }
                                                }
                                            }

                                            ~.text 
                                            {
                                                color: $yellow;
                                            }
                                        }
                                    }
                                    
                                    .icon__wrapper 
                                    {
                                        display: inline-block;
                                        position: relative;
                                        right: rem-calc(25);
                                        bottom: rem-calc(5);

                                        .icon 
                                        {
                                            .line
                                            {
                                                width: rem-calc(10);
                                                height: rem-calc(1);
                                                position: absolute;
                                                background-color: $gray;
                                                
                                                &.line-1
                                                {

                                                }

                                                &.line-2
                                                {
                                                    transform: rotate(90deg);
                                                }
                                            }
                                        }
                                    }

                                    .text 
                                    {
                                        display: inline-block;
                                        font-family: 'FreightTextBookItalic';
                                        font-size: rem-calc(16);
                                        color: $gray;

                                        @include breakpoint(medium only)
                                        {
                                            font-size: rem-calc(22);
                                        }
                                    }
                                }
                                
                                &:hover, &.active
                                {
                                    background-color: $blue;

                                    .icon__wrapper
                                    {
                                        .icon 
                                        {
                                            .line 
                                            {
                                                background-color: $yellow;
                                            }
                                        }
                                    }

                                    /*
                                    .icon__wrapper
                                    {
                                        bottom: 0px; 

                                        .icon 
                                        {
                                            transform: rotate(-10deg);
                                            position: relative;
                                            bottom: rem-calc(1);

                                            .line 
                                            {
                                                background-color: $yellow;
                                                
                                                &.line-1
                                                {
                                                    width: rem-calc(16);
                                                    transform: rotate(-45deg);
                                                    left: rem-calc(4);
                                                    bottom: rem-calc(2);
                                                }

                                                &.line-2
                                                {
                                                    width: rem-calc(8);
                                                    transform: rotate(45deg);
                                                }
                                            }
                                        }
                                    }
                                    */

                                    .text
                                    {
                                        color: $yellow;
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }   
    }
}