.down__arrow__wrapper
{
	height: 0px;
	
	.down__arrow__outer
	{
		border-radius: 100px;
		margin: auto;
		
		$size: 11;
		width: rem-calc(32);
		height: rem-calc(32);
		border: 1px solid $yellow;
		cursor: pointer;

		.down__arrow__inner
		{
			position: relative;

			.line 
			{
				width: rem-calc($size);
				height: rem-calc(1);
				position: absolute;
				background: $yellow;
				top: rem-calc(17);

				&.line-1
				{
					transform: rotate(45deg);
					left: rem-calc(6);
				}

				&.line-2
				{
					transform: rotate(-45deg);
					right: rem-calc(6);	
				}
			}
		}
	}
}