.contact__overlay, .who__are__you__overlay, .your__email__address__overlay {
    .contact__overlay__content {
        .contact__header {
            .left {

                @include breakpoint(medium up) {
                    display: inline-block;
                    width:   50%;
                }

                @include breakpoint(large up) {
                    width: 42%;
                }

                .contact__overlay__title {
                    margin: 0px;

                    @include breakpoint(large up) {
                        margin-right: rem-calc(80);
                    }
                }

                .slash__wrapper {
                    position: relative;
                    bottom:   rem-calc(22);

                    @include breakpoint(medium only) {
                        bottom: rem-calc(45);
                        left:   rem-calc(8);
                    }

                    @include breakpoint(large up) {
                        bottom: 0px;
                        right:  rem-calc(60);
                    }

                    .slash {
                        position: relative;
                        bottom:   rem-calc(5);
                        left:     rem-calc(10);

                        @include breakpoint(small only) {
                            height: rem-calc(38);
                        }

                        @include breakpoint(medium up) {
                            right: rem-calc(40);
                        }

                        @include breakpoint(medium only) {
                            height: rem-calc(70);
                        }

                        @include breakpoint(large up) {
                            bottom: rem-calc(60);
                        }
                    }
                }

                .contact__overlay__description {
                    margin: 0px;
                }

            }

            .right {
                @include breakpoint(small only) {
                    padding-top:   rem-calc(5);
                    margin-bottom: rem-calc(30);
                }

                @include breakpoint(medium up) {
                    display:       inline-block;
                    width:         45%;
                    float:         right;
                    padding-right: rem-calc(70);
                }

                @include breakpoint(large up) {
                    width: 56%;
                }

                .contact__details {
                    text-transform: uppercase;
                    font-family:    'Gotham-Medium';
                    font-size:      rem-calc(8);
                    letter-spacing: rem-calc(6);
                    color:          $yellow;
                    position:       relative;

                    @include breakpoint(medium up) {
                        float: right;
                    }

                    @include breakpoint(medium only) {
                        position:  absolute;
                        top:       rem-calc(60);
                        right:     rem-calc(100);
                        font-size: rem-calc(10);
                    }

                    @include breakpoint(large up) {
                        //top:       rem-calc(20);
                        top:       rem-calc(12);
                        font-size: rem-calc(16);
                        //right: rem-calc(100);
                    }

                    .slashes {
                        display:       inline-block;
                        padding-left:  rem-calc(8);
                        padding-right: rem-calc(15);

                        @include breakpoint(medium down) {
                            padding-left:  0px;
                            padding-right: rem-calc(8);
                        }

                        .slash {
                            display: inline-block;
                            width:   rem-calc(4);
                        }
                    }
                }
            }
        }

        form {
            height: 60%;
        }
    }
}   