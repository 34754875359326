.footer {
    width:            100%;
    //background-color: $dark-blue;
    background-color: $white;
    //border-top: 1px solid $yellow;
    padding:          rem-calc(30) 0px;

    @include breakpoint(large up) {
        padding: rem-calc(30) rem-calc(100);
    }

    &.blue {
        background-color: $blue;
    }

    .inner {
        display: inline-block;
    }

    .footer__left {
        text-align: left;

        h3 {
            color:          $gray;
            font-size:      rem-calc(9);
            font-family:    'Gotham-Medium';
            letter-spacing: rem-calc(4);
            text-transform: uppercase;
        }

        a, span {
            color:       $gray;
            font-family: 'FreightTextMediumItalic';
            font-size:   rem-calc(16.04);
            text-align:  center;
        }
    }

    .footer__middle {
        color:          $gray;
        text-align:     center;
        letter-spacing: rem-calc(4);
        text-transform: uppercase;

        .date {
            font-size:     rem-calc(9);
            padding-left:  rem-calc(0);
            font-family:   'Gotham-Medium';
            display:       block;
            margin-bottom: rem-calc(16);
        }

        .heading {
            margin-bottom:  rem-calc(12);
            font-size:      rem-calc(11);
            padding-left:   rem-calc(0);
            font-family:    'ButlerStencil-ExtraBold';
            display:        block;
            margin-bottom:  rem-calc(10);
            letter-spacing: rem-calc(6);
        }

        .sub-heading {
            font-family:  'Gotham-Medium';
            font-size:    rem-calc(9);
            padding-left: rem-calc(0);
            display:      block;
        }
    }

    .footer__right {
        height:     100%;
        text-align: right;

        .down__arrow__wrapper {
            height:   0px;
            position: absolute;
            left:     calc(50% - 16px);
            top:      rem-calc(-16);

            .down__arrow__outer {
                transform: rotate(180deg);
            }
        }

        form {
            padding-top: rem-calc(10);

            @include breakpoint(medium only) {
                padding-right: 0;
            }

            input {
                margin:                0;
                color:                 $gray;
                background:            transparent;
                box-shadow:            none;
                font-family:           'Gotham-Medium';
                font-size:             rem-calc(9);
                letter-spacing:        rem-calc(2);
                float:                 right;
                width:                 12.125rem;
                border:                none;
                border-bottom:         $gray solid 1px;
                // Magifying glass icon
                background:            url('/img/mglass.png') no-repeat scroll 0 14px;
                background-position-y: rem-calc(13);
                background-position-x: rem-calc(-6);
                padding-left:          rem-calc(30);
                padding-bottom:        0;

                &::placeholder {
                    letter-spacing: rem-calc(4);
                    text-transform: uppercase;
                }

                @include breakpoint(medium only) {
                    margin-right: rem-calc(25);
                }
            }
        }
    }
}

.upper__footer {
    background-color: lighten($light-gray, 25%);
    padding-top:      rem-calc(60);
    padding-bottom:   rem-calc(50);

    @include breakpoint(large up) 
    {
        padding: rem-calc(60) rem-calc(100) rem-calc(50);
    }
    
    position:         relative;

    .talk_portfolio,
    .talk_us,
    .talk_process {
        font-size:      rem-calc(10);
        font-family:    'Gotham-Medium';
        text-transform: uppercase;
    }

    .inner {
        display:    inline-block;
        text-align: center;
    }

    .align-middle {
        height: 100%;
    }

    .down__arrow__wrapper {
        .down__arrow__outer {
            position:  absolute;
            top:       rem-calc(-16);
            left:      calc(50% - 16px);
            transform: rotate(180deg);
            z-index: 1;

            .down__arrow__inner
            {
                bottom: rem-calc(3);
            }
        }
    }

    // Portfolio
    .talk_portfolio {
        text-align: left;

        a {
            color:      $dark-blue;
            text-align: center;

            span:nth-child(1) {
                text-transform: lowercase;
                font-family:    'FreightTextMediumItalic';
                font-size:      rem-calc(16);
                letter-spacing: rem-calc(1);

                @include breakpoint(small only) {
                    font-size: rem-calc(12);
                }
            }

            span:last-child {
                font-family:    'Gotham-Medium';
                font-size:      rem-calc(14);
                letter-spacing: rem-calc(6);

                @include breakpoint(small only) {
                    font-size: rem-calc(8);
                }
            }
        }
    }

    // Talk to us
    .talk_us {
        text-align: center;

        .talk_title,
        .back_title {
            font-family:    'ButlerStencil-ExtraBold';
            letter-spacing: rem-calc(10);
            font-size:      rem-calc(17);
            color:          $dark-blue;
            cursor:         pointer;

            @include breakpoint(small only) {
                font-size:      rem-calc(10);
                letter-spacing: rem-calc(5);
            }
        }

        .dash 
        {
            width: rem-calc(40);
            height: rem-calc(1);
            background: $dark-blue;
            position: relative;
            top: rem-calc(20);
            margin: auto;
        }
    }

    // Process
    .talk_process {
        text-align: right;

        a {
            color:      $dark-blue;
            text-align: center;

            span:nth-child(1) {
                text-transform: lowercase;
                font-family:    'FreightTextMediumItalic';
                font-size:      rem-calc(16);
                letter-spacing: rem-calc(1);

                @include breakpoint(small only) {
                    font-size: rem-calc(12);
                }
            }

            span:last-child {
                font-family:    'Gotham-Medium';
                font-size:      rem-calc(14);
                letter-spacing: rem-calc(6);

                @include breakpoint(small only) {
                    font-size: rem-calc(8);
                }
            }
        }
    }

    .related__projects {
        margin:     0 50px;
        margin-top: rem-calc(40);
    }

    /*
    .row
    {
        @include breakpoint(large)
        {
            @include flex-grid-row('collapse', 'expand');
        }
    } 

    @include breakpoint(small only)
    {
        text-align: center;
        
        .columns 
        {
            padding: rem-calc(20);
        }

        div:nth-child(1)
        {
            a
            {
                float:none;
            }

            .talk_portfolio
            {
                span
                {
                    padding-left: rem-calc(0);
                    padding-right: rem-calc(0);
                }
            }

            .talk_process
            {
                span
                {
                    padding-left: rem-calc(0);
                    padding-right: rem-calc(0);
                }
            }
        }

        .up-arrow
        {
            top: -13.1875rem;
        }
    }
    */
}