body {
    background-color: $white;
}

.home {
    .off-canvas-content {
        margin-top: 0px;

        .title-bar {
            @include breakpoint(small only) {
                //background: transparent;
                //position:   relative;

                position: fixed;
                top: 0;
                z-index: 100;
            }

            @include breakpoint(medium only) {
                height:         0px;
                background:     transparent;
                padding:        rem-calc(36);
                padding-top:    0px;
                padding-bottom: 0px;
                position:       relative;
                top:            rem-calc(20);
            }

            @include breakpoint(large up) {
                padding-right: 0px;
            }
        }

        .slash__wrapper {
            @include breakpoint(medium down) {
                display: none;
            }
        }

        .bar__upper {
            img {
                @include breakpoint(medium only) {
                    height: rem-calc(40);
                }
            }
        }

        .bar__middle {
            .open-icon {
                .open-icon-inner {
                    .line {
                        @include breakpoint(medium only) {
                            width:         rem-calc(30);
                            height:        rem-calc(3);
                            margin-bottom: rem-calc(10);
                        }
                    }
                }
            }
        }
    }

    .is-open-right .title-bar {
        @include breakpoint(small only) {
            display: none;
        }
    }

    .image__list {
        padding: 0px rem-calc(10);

        @include breakpoint(large up)
        {
            padding-right: 0px;
            margin-right: rem-calc(-5);
        }
    }

    #philosophy {

        text-align: center;
        padding-bottom: rem-calc(50);

        @include breakpoint(medium up) {
            max-width: rem-calc(660);
            margin:    auto;
        }

        @include breakpoint(large up) {
            padding: rem-calc(40) 0px rem-calc(100) 0px;
        }

        .text {
            @include breakpoint(small only) {
                font-size:   rem-calc(20);
                line-height: rem-calc(32);
            }
        }

        .slashes {
            .slash {
                font-size: rem-calc(12);
                margin:    rem-calc(10) 0px;
            }
        }
    }

    #h__our__process {
        padding-bottom: rem-calc(30);

        @include breakpoint(large up) {
            text-align: center;
            padding:    rem-calc(40) 0px rem-calc(80) 0px;
        }

        .our__process__heading {
            padding: rem-calc(40) 0px rem-calc(50) 0px;

            .heading__main {
                text-transform: uppercase;
                text-align:     center;
                font-family:    'Gotham-Medium';
                font-size:      rem-calc(16);
                color:          $gray;
                letter-spacing: rem-calc(12);
                margin-bottom:  rem-calc(15);
            }

            .dash {
                margin:     auto;
                width:      rem-calc(35);
                height:     rem-calc(1);
                background: $yellow;
            }
        }

        .heading {
            text-transform: uppercase;
            font-family:    'ButlerStencil-ExtraBold';
            font-size:      rem-calc(10);
            color:          $dark-blue;
            letter-spacing: rem-calc(6);
            position:       relative;
            top:            rem-calc(10);
            margin-left:    rem-calc(33);

            @include breakpoint(medium up) {
                margin-left: 0px;
            }

            @include breakpoint(1400px) {
                font-size: rem-calc(14);
            }
        }

        .process__block {
            vertical-align: top;

            @include breakpoint(medium up) {
                width:  rem-calc(400);
                margin: auto;
            }

            @include breakpoint(large up) {
                display:    inline-block;
                text-align: left;
                width:      auto;

                .text__block {
                    max-width: rem-calc(300);

                    @include breakpoint(1400px) {
                        max-width: rem-calc(450);
                        .text {
                            font-size:   rem-calc(14);
                            line-height: rem-calc(44);
                        }
                    }
                }
            }

            .text__block__wrapper
            {
                .text__block
                {
                    position: relative;

                    @include breakpoint(large up)
                    {
                        padding-top: rem-calc(30);
                        padding-left: rem-calc(54);
                    }

                    .underlay__wrapper
                    {
                        @include breakpoint(large up)
                        {
                            position: absolute !important;
                            top: rem-calc(38) !important;
                            left: rem-calc(28) !important;
                        }
                    }
                }
            }
        }
    }

    #image__lower {
        margin: 0px;

        @include breakpoint(large up) {
            height: rem-calc(500);
        }

        @include breakpoint(1600px) {
            height: rem-calc(700);
        }

        .content__image {
            width: 100%;

            @include breakpoint(1600px) {
                height: rem-calc(700);
            }

            @include breakpoint(small only) {
                background-attachment: unset !important;
                background-size: cover;
            }
        }
    }

    #h__culture {
        position: relative;

        .text__block {
            @include breakpoint(medium up) {
                padding: rem-calc(80) 0px;
            }

            .heading {
                font-size:      rem-calc(16);
                color:          $gray;
                letter-spacing: rem-calc(12);
            }

            .slashes {
                width:         rem-calc(50);
                margin:        auto;
                margin-bottom: rem-calc(30);
                border-bottom: 1px solid $yellow;

                .slash {
                    color: transparent;
                }
            }
        }
    }
}


div#hero-container
{
    position: relative;
    height: 100% !important;
    width: 100% !important;
    overflow: hidden;
}

div#hero-container > .hero-inner-container
{
    position: relative;
    width: 100%;
    height: 100%;
    border: 36px solid #fff;
    // border-top: 0;
    // border-left: 0;
    // border-right: 0;
    box-sizing: border-box;

}

div#hero-container > .hero-inner-container > section
{
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-repeat: no-repeat;
    background-position: 50% 50%;
    background-size: 120%;
    z-index: 1;
}

div#hero-container > .hero-inner-container > section.layer-two
{
    opacity: .6;
}

div#hero-container > .hero-inner-container > div
{
    display: table;
    position: relative;
    height: 100%;
    width: 50%;
    box-sizing: border-box;
    padding: 10px;
    float: left;
    z-index: 2;
    text-align: right;
    top: -25%;
    bottom: 0;
}

div#hero-container > .hero-inner-container > div:last-child
{
    text-align: left;
    top: auto;
    bottom: -25%;
}

div#hero-container > .hero-inner-container > div h1
{
    display: table-cell;
    vertical-align: middle;
    color:          $yellow;
    opacity:        0.9;
    font-family:    'ButlerStencil-ExtraBold';
    font-size:      rem-calc(20);
    //font-size: rem-calc(10);
    //text-align:     center;
    text-transform: uppercase;
    letter-spacing: 10px;

    @include breakpoint(medium) 
    {
        font-size: rem-calc(44);
    }

    @include breakpoint(large) 
    {
        font-size: rem-calc(54);
        opacity:   0.8;
    }
}

.home
{
    .logo__wrapper 
    {
        display:      none;
        padding-top:  rem-calc(76);
        padding-left: rem-calc(76);
        z-index:      2;

        @include breakpoint(large up) 
        {
            display:  block;
            position: absolute;
            top:      0px;
        }

        img 
        {
            height: rem-calc(40);
        }
    }
}


// Top banner component
#h__banner {
    height:        100%;
    padding:       rem-calc(36);
    padding-right: 0px;
    z-index:       0;

    @include breakpoint(medium down) {
        padding: 0px;
    }

    .align-middle {
        height: 100%;
    }

    // Banner image
    .background {
        background-image:    url('/img/school3.jpg');
        background-size:     120%;
        background-position: center center;
        background-repeat:   no-repeat;
        height:              100%;
        position:            relative;

        @include breakpoint(small only) {
            position:        relative;
            bottom:          rem-calc(50);
            background-size: cover;
        }

        @include breakpoint(medium only) {
            background-size: cover;
        }

        // Adjust background image on large screens
        @include breakpoint(large) {
            //height: 51rem;
        }

        .sub__background {
            height: 100%;

            .sub__background__image {
                background-size: 0%;

                @include breakpoint(large up) {
                    background-image:    url('/img/home__hero__2.jpg');
                    display:             block;
                    content:             "";
                    width:               100%;
                    height:              100%;
                    opacity:             0.5;
                    position:            absolute;
                    top:                 0px;
                    left:                0px;
                    background-size:     120%;
                    background-position: center center;
                    background-repeat:   no-repeat;
                    z-index:             1;
                    //position: relative;
                }
            }

            /*&:before
            {
                background-size: 0%;
            
                @include breakpoint(large up)
                {
                    background-image: url('/img/home__hero__2.jpg');
                    display: block;
                    content: "";
                    width: 100%;
                    height: 100%;
                    opacity: 0.5;
                    position: absolute !important;
                    top: 0px;
                    left: 0px;
                    background-size: 120%;
                    background-position: 50% 50%;
                    background-repeat: no-repeat;
                    position: relative;
                }
            }*/

            .logo__wrapper {
                display:      none;
                padding-top:  rem-calc(36);
                padding-left: rem-calc(36);
                z-index:      2;

                @include breakpoint(large up) {
                    display:  block;
                    position: absolute;
                    top:      0px;
                }

                img {
                    height: rem-calc(40);
                }
            }

            .hero__text__container {
                text-align: center;
                height:     100%;
                position:   relative;
                overflow:   hidden;
                z-index:    2;

                // Text ontop of banner
                h1 {
                    color:          $yellow;
                    opacity:        0.9;
                    font-family:    'ButlerStencil-ExtraBold';
                    font-size:      rem-calc(20);
                    //font-size: rem-calc(10);
                    text-align:     center;
                    text-transform: uppercase;
                    letter-spacing: 10px;

                    @include breakpoint(medium) {
                        font-size: rem-calc(44);
                    }

                    @include breakpoint(large) {
                        font-size: rem-calc(54);
                        opacity:   0.8;
                        //text-align: left;
                        //padding-left: 4.75rem; 
                    }
                }

                .left {
                    display:       inline-block;
                    padding-right: rem-calc(20);

                    @include breakpoint(large) {
                        width:      50%;
                        margin-top: rem-calc(-83);
                        position:   absolute;
                        left:       0;
                        top:        -83px;
                    }

                    h1 {
                        @include breakpoint(large) {
                            font-size:  rem-calc(54);
                            text-align: right;
                            //padding-left: 4.75rem; 
                        }
                    }
                }

                .right {
                    display: inline-block;

                    @include breakpoint(large) {
                        width:         50%;
                        margin-bottom: rem-calc(-83);
                        position:      absolute;
                        bottom:        -83px;
                        right:         0;
                    }

                    h1 {
                        @include breakpoint(large) {
                            font-size:  rem-calc(54);
                            text-align: left;
                            //padding-left: 4.75rem; 
                        }
                    }
                }

                .inner {
                    width:    100%;
                    position: absolute;
                    top:      calc(50% - 100px);
                }
            }
        }
    }
}

#home__page {
    background: #ffffff;
    position:   relative;
    z-index:    99;

    .text_container {
        left: 0;
        margin: 0 auto;
        position:relative !important;
        top: rem-calc(-28);
        bottom: auto;
        z-index: 100;

        @include breakpoint(small only)
        {
            top: rem-calc(-90);
        }

        @include breakpoint(medium only)
        {
            top: rem-calc(-40);
        }

        @include breakpoint(large)
        {
            display: none;
            left: rem-calc(-5);
        }
    }
}

// The box for the What We Do overlay
.text_container {
    text-align:       center;
    position:         relative;
    //bottom:           rem-calc(47);
    background-color: $white;
    padding:          0px;
    padding-bottom:   rem-calc(10);
    //width:            rem-calc(250);
    width:            rem-calc(260);
    margin:           auto;
    z-index:          2;

    @include breakpoint(medium up) 
    {
        position: absolute;
        //bottom:   rem-calc(-2);
        left:     calc(50% - 150px);
    }

    @include breakpoint(large up) 
    {
        left: calc(50% - 153px);
        bottom: rem-calc(15);

        &.active {
            //bottom: 120%;
        }
    }

    p {
        font-family:    'ButlerStencil-ExtraBold';
        color:          $dark-blue;
        text-transform: uppercase;
        text-align:     center;
        font-size:      rem-calc(14);
        letter-spacing: rem-calc(6);
        position:       relative;
        top:            rem-calc(10);
    }
}

// Creates triangle slot for the overlay
.text_container:before,
.text_container:after {

    content:       '';
    display:       block;
    position:      absolute;
    bottom:        rem-calc(35);
    //width:         rem-calc(140);
    width:         rem-calc(153);
    border-bottom: 35px solid $white;
}

.text_container:before {
    left:         0px;
    border-right: 35px solid transparent;
}

.text_container:after {
    right:       0px;
    border-left: 35px solid transparent;
}

// Styling for link to view our work
.view__our__work {
    text-align: center;
    display: inline-block;

    a {
        font-family:    'ButlerStencil-ExtraBold';
        letter-spacing: rem-calc(4);
        text-transform: uppercase;
        color:          $crayola;
        font-size:      rem-calc(10);
        text-align:     center;
        padding-bottom: rem-calc(5);
        display:        inline-block;
        //border-bottom:  1px solid $crayola;
        font-weight:    bolder;

        &:not(.active) {
            &:before {
                content:          "";
                position:         absolute;
                width:            0%;
                height:           1px;
                bottom:           -1px;
                background-color: $yellow;
                transition:       all 0.4s linear;
            }

            &:hover:before {
                width: 100%;
            }
        }
    }

}

/*


// Large image component
#h_fpo
{
    .img 
    {
        padding-bottom: rem-calc(580);
        background-repeat: no-repeat;
        background-position: 50% 50%;
        background-size: cover;
    }
}


// Culture
#h_culture 
{
    padding-top: 5.05rem;
    padding-bottom: rem-calc(124);

    .culture_divider 
    {
        width: rem-calc(40);
        margin-left: auto;
        margin-right: auto;
        margin-bottom: rem-calc(33);
        margin-top: rem-calc(25);
        border: 1px solid $yellow;
    }

    h2 
    {
        font-family: 'Gotham-Medium';
        text-align: center;
        margin-bottom: rem-calc(0);
        letter-spacing: 0.691rem;
        font-size: rem-calc(15);
        text-transform: uppercase;
        color: $gray;
        
    }

    h3 
    {
        font-family: 'FreightTextBookItalic';
        text-align: center;
        color: $dark-blue;
        font-size: rem-calc(28);
        letter-spacing: rem-calc(4); 

        .about_culture
        {
            line-height: 1.8;
            margin-bottom: 1rem;
        }
    }

    .left_culture_text
    {
        // Safari
        -webkit-transform: rotate(270deg);

        // Firefox 
        -moz-transform: rotate(270deg);

        // IE 
        -ms-transform: rotate(270deg);

        // Internet Explorer 
        filter: progid:DXImageTransform.Microsoft.BasicImage(rotation=2);
        font-family: 'ButlerStencil-ExtraBold';
        text-transform: uppercase;
        letter-spacing: 0.591rem;
        display: inline-block;
        color: $gray;
        opacity: 0.6;
        margin-top: 9rem;
        margin-left: -3rem;
        font-size: rem-calc(12.84);
    }

    .right_culture_text
    {
        // Safari 
        -webkit-transform: rotate(90deg);
        // Firefox 
        -moz-transform: rotate(90deg);
        // IE 
        -ms-transform: rotate(90deg);
        // Internet Explorer 
        filter: progid:DXImageTransform.Microsoft.BasicImage(rotation=2);

        font-family: 'ButlerStencil-ExtraBold';
        text-transform: uppercase;
        letter-spacing: 0.591rem;
        display: inline-block;
        color: $gray;
        opacity: 0.6;
        margin-top: 9rem;
        margin-left: -2.85rem;
        font-size: rem-calc(12.84);
    }
}
*/

#circle {
    width:                 rem-calc(36);
    height:                rem-calc(36);
    background:            transparent;
    -moz-border-radius:    50%;
    -webkit-border-radius: 50%;
    border-radius:         50%;
    border:                2px solid $orange;
    position:              absolute;
    top:                   rem-calc(4118);
    left:                  rem-calc(938);
}

#circle:hover {
    background: $orange;
}