// Foundation
@import "./node_modules/foundation-sites/scss/foundation";

// Font Awesome
$fa-font-path: "/fonts/fontawesome/";
@import "font-awesome/fontawesome";
@import "font-awesome/fa-light";
@import "font-awesome/fa-brands";
@import "font-awesome/fa-regular";
@import "font-awesome/fa-solid";


// Slick
@import "./node_modules/slick-carousel/slick/slick.scss";

$global-flexbox: true;
// Foundation Imports
@include foundation-global-styles;

$offcanvas-animation-speed: 0.7s;

// @include foundation-grid;
@include foundation-flex-grid;
@include foundation-flex-classes;
@include foundation-typography;
@include foundation-forms;
@include foundation-button;
@include foundation-accordion;
@include foundation-accordion-menu;
@include foundation-badge;
@include foundation-breadcrumbs;
@include foundation-button-group;
@include foundation-callout;
@include foundation-card;
@include foundation-close-button;
@include foundation-menu;
@include foundation-menu-icon;
@include foundation-drilldown-menu;
@include foundation-dropdown;
@include foundation-dropdown-menu;
@include foundation-responsive-embed;
@include foundation-label;
@include foundation-media-object;
@include foundation-off-canvas;
@include foundation-orbit;
@include foundation-pagination;
@include foundation-progress-bar;
@include foundation-slider;
@include foundation-sticky;
@include foundation-reveal;
@include foundation-switch;
@include foundation-table;
@include foundation-tabs;
@include foundation-thumbnail;
@include foundation-title-bar;
@include foundation-tooltip;
@include foundation-top-bar;
@include foundation-visibility-classes;
@include foundation-float-classes;

// Globals
@import 'global/colors';
@import 'global/mixins';
@import 'global/typography';

// Base
@import 'base';

// Modules
@import 'modules/header';
@import 'modules/footer';

// Components
@import 'components/buttons';
@import 'components/slash';
@import 'components/close-icon';
@import 'components/sticker';
@import 'components/down-arrow';
@import 'components/forms';
@import 'components/nav';
@import 'components/slick';
@import 'components/overlay-menu';
@import 'components/menu-bar';
@import 'components/image-item';
@import 'components/text-block';
@import 'components/content-block';
@import 'components/off-canvas';
@import 'components/onepage-scroll';
@import 'components/side-labels';
@import 'components/overview-split';

// Site Templates
@import 'templates/home';
@import 'templates/culture';
@import 'templates/process';
@import 'templates/portfolio';
@import 'templates/casestudy';
@import 'templates/project';
@import 'templates/search-overlay';
@import 'templates/filter-overlay';
@import 'templates/contact-overlay';
@import 'templates/slideshow-overlay';
@import 'templates/career';
@import 'templates/blog';
@import 'templates/error';