.slick-arrow
{
	&.slick-prev,
	&.slick-next
	{
		//color: darken($white, 20%);
		color: transparent;
		font-size: rem-calc(60);
		width :rem-calc(14);
		height: rem-calc(14);
		top: calc(50% - 12px);
		border-bottom: 1px solid $yellow;
		border-left: 1px solid $yellow;
		z-index: 2;

		&:before
		{
			display: none;
		}

		&:focus
		{
			outline: none;
		}
	}

	&.slick-prev 
	{
		left: rem-calc(40);
		transform: rotate(45deg);

		@include breakpoint(small only)
		{
			left: rem-calc(20)
		}
	}

	&.slick-next 
	{
		right: rem-calc(40);
		transform: rotate(225deg);

		@include breakpoint(small only)
		{
			right: rem-calc(20)
		}
	}
}

.slide 
{
	&:focus
	{
		outline: none;
	}
}