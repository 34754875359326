.text__block__wrapper
{
	.text__block
	{
		padding: rem-calc(35) 0px;

		.heading
		{
			text-transform: uppercase;
			font-family: 'Gotham-Medium';
			color: $yellow;
			letter-spacing: rem-calc(6);
		}

		.text
		{
			&.clean
			{
				text-transform: uppercase;
				font-family: 'Gotham-Medium';
				font-size: rem-calc(8);
				line-height: rem-calc(28);
				letter-spacing: rem-calc(4);
				color: $ash-gray;

				@include breakpoint(medium up)
				{
					font-size: rem-calc(10);
					line-height: rem-calc(32);
					letter-spacing: rem-calc(6);
				}
			}

			&.fancy
			{
				font-family: 'FreightTextBookItalic';
				font-size: rem-calc(14);
				line-height: rem-calc(24);
				letter-spacing: rem-calc(1);
				word-spacing: rem-calc(1);
				color: $dark-blue;

				@include breakpoint(medium up)
				{
					font-size: rem-calc(22);
					line-height: rem-calc(36);
				}
			}
		}
		
		.signature
		{
	    	display: inline-block;
		    text-transform: uppercase;
		    font-size: rem-calc(10);
		    font-family: 'ButlerStencil-ExtraBold';
		    letter-spacing: rem-calc(4);
		    color: $dark-blue;
		    margin-top: rem-calc(20);
		    padding-bottom: rem-calc(25);
	        display: inline-block;
		    
		    div
		    {
		    	display: inline-block;
		    }

		    .slashes
		    {
		    	width: rem-calc(12);
		    	margin: 0px rem-calc(2);

		        .slash
		        {
		        	width: 0px;
		        }
		    }

		    .dash 
		    {
		    	width: rem-calc(50);
		    	height: rem-calc(1);
		    }
		}

		.dash
		{
			width: rem-calc(30);
			height: rem-calc(1);
			background: $yellow;
		}

		&.leaf
		{
			.heading 
			{
				font-size: rem-calc(14);
				margin-bottom: rem-calc(10);
				margin-left: rem-calc(25);

				@include breakpoint(medium up)
				{
					font-size: rem-calc(18);
					margin-bottom: rem-calc(15);
				}
			}

			.text 
			{
				margin-left: rem-calc(50);
				border-left: 1px solid $yellow;
				padding-right: rem-calc(50);
				padding-left: rem-calc(15);

				@include breakpoint(medium up)
				{
					padding-left: rem-calc(20);
				}
			}
		}
		
		&.centered
		{
			text-align: center;

			@include breakpoint(large up)
			{
				max-width: rem-calc(800);
				margin: auto;
			}
			
			.heading 
			{
				font-size: rem-calc(8);
				//font-family: 'Gotham-Bold';

				@include breakpoint(medium up)
				{
					font-size: rem-calc(10);
				}
			}

			.slashes
			{
				padding-right: rem-calc(5);

				.slash 
				{
					display: inline-block;
					font-size: rem-calc(10);
					color: $yellow;

					@include breakpoint(medium up)
					{
						font-size: rem-calc(16);
					}
				}
			}

			.text 
			{
				padding: 0px rem-calc(50);

				@include breakpoint(medium up)
				{
					font-size: rem-calc(30);
					line-height: rem-calc(52);
				}
			}
		}

		&.underlayed
		{
			padding-left: rem-calc(65);
			padding-right: rem-calc(20);

			@include breakpoint(medium up)
			{
				padding-left: rem-calc(25);
			}

			@include breakpoint(large up)
			{
				max-width: rem-calc(380);
			}

			.underlay__wrapper
			{
				height: 0px;

				.underlay 
				{
					text-transform: uppercase;
					font-family: 'ButlerStencil-ExtraBold';
					font-size: rem-calc(80);
					letter-spacing: rem-calc(6);
					color: $yellow;
					position: relative;
					bottom: rem-calc(30);
					right: rem-calc(34);
					letter-spacing: rem-calc(6);
					z-index: -1;
				}
			}
		}
	}
}

.gray
{
	color: $gray;
}