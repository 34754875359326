$black: #000;
$white: #fff;

$dark-gray: #A8A7A7;
$gray: #A7A6A6;
$light-gray: #A6A5A5;
$clear-gray: #CCCBCB;
//$ash-gray: #CDCDCD;
$ash-gray: #afafaf;
$lighter-gray: #eee;

$crayola: #FCBD31;
$orange: #FFC033;
$dark-orange: #DA9F2C;
$clear-orange: #FFBF33;

$yellow: #FDBD32;
$dark-yellow: #FBC54B;

$pink: #FACBCC;
$hot-pink: #E80E8B;
$dark-blue: #25354F;
$blue: #25354F;

//specific greys
$footer-background-grey: #A6A5A5;
$footer-text-grey: #A7A6A6;