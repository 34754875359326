.sticker__wrapper
{
	width: 0px;
	height: 0px;
	position: relative;
	z-index: 99;

	.sticker 
	{
		width: rem-calc(100);
		height: rem-calc(100);
		position: absolute;
		bottom: rem-calc(-10);
		left: rem-calc(-15);

		@include breakpoint(large up)
		{
			//left: rem-calc(-30);
			width: rem-calc(120);
			height: rem-calc(120);
		}

		img 
		{
			width: 100%;
			height: 100%;
		}
	}
}