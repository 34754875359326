.close__icon__wrapper
{
    display: inline-block;
    float: right;
    margin-right: rem-calc(40);
    position: absolute;
    top: rem-calc(50);
    right: 0px;
    z-index: 10;

    .close__icon 
    {
        position: relative;
        width: rem-calc(40);
        height: rem-calc(40);
        cursor: pointer;

        @include breakpoint(small only)
        {
            bottom: rem-calc(5);
            left: rem-calc(10);
        }

        .line 
        {
            width: rem-calc(46);
            height: rem-calc(1);
            position: absolute;
            bottom: rem-calc(20);
            left: rem-calc(-3);
            background-color: $yellow;

            &.line-1
            {
                transform: rotate(45deg);
            }

            &.line-2
            {
                transform: rotate(-45deg);
            }
        }
    
        &:hover {
            .line {
                background-color: $blue;
            }
        }
    }
}