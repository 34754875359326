.portfolio 
{
    .page__label
    {
        bottom: rem-calc(3) !important;

        &::after
        {
            content: "Architecture";
            //font-family: 'Gotham-Book';

            @include breakpoint(medium down)
            {
                position: relative;
                bottom: rem-calc(8);
            }
        }
    }

    #portfolio__gallery
    {
        @include breakpoint(large up)
        {
            //margin-top: rem-calc(130);
            margin-top: rem-calc(142);
            //margin-top: rem-calc(190);
        }
        .jscroll-added
        {
            .image__list
            {
                @include breakpoint(large up)
                {
                    margin-left: rem-calc(0);
                    margin-right: rem-calc(-5);
                }
            }
        }

        .image__list
        {
            @include breakpoint(small only)
            {
                padding: 0px rem-calc(10);
            }

            @include breakpoint(medium only)
            {
                padding: 0px rem-calc(5);
            }

            @include breakpoint(large up)
            {
                margin-left: rem-calc(25);
                margin-right: rem-calc(-5);
            }
        }
    }

    .horizontal__rule__container
    {
        @include breakpoint(medium only)
        {
            padding: 0px rem-calc(10);
        }

        @include breakpoint(large up)
        {
            padding: 0px rem-calc(30);
        }

        .horizontal__rule
        {
            width: 100%;
            height: 1px;
            background-color: $yellow;
        }
    }
}



